import { trigger, state, style, transition, animate } from '@angular/animations';

export const buttonAnimation = trigger('buttonState', [
  state('default', style({
    backgroundColor: 'black',
    color: 'white',
    width: '120px',
    fontSize: '12px',
    border: 'none',
  })),
  state('subscribed', style({
    backgroundColor: 'white',
    color: 'black',
    fontSize: '35px',
    width: '160px',
  })),
  transition('default => subscribed', [
    animate('0.7s'),
  ]),
]);

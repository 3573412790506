// This file can be replaced during build by using the `fileReplacements` array.
// `ng build --prod` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

export const environment = {
  production: true,
  
  contentful: {
    space: 'rjdfksfdj1h0',
    accessToken: '3abebd60ace29fc4581276bd2287f3a4d3640c3e446e74523a008ab2ed4e64b5',
    environment: 'master'
  },

  firebase: {
    apiKey: "AIzaSyAJTBDw4p6NN9obam2Y3JQOIbU1RnlkA2s",
    authDomain: "irina-shilova.firebaseapp.com",
    databaseURL: "https://irina-shilova.firebaseio.com",
    projectId: "irina-shilova",
    storageBucket: "irina-shilova.appspot.com",
    messagingSenderId: "841965408439",
    appId: "1:841965408439:web:06d0cf593c38860621fd32",
    measurementId: "G-PPFDL62QN7"
  }
};

/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
// import 'zone.js/plugins/zone-error';  // Included with Angular CLI.

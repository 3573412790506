import { NgModule } from '@angular/core';
import { BlogComponent } from './blog.component';
import { RouterModule } from '@angular/router';
import { CommonModule } from '@angular/common';
import { BlogPageComponent } from './blog-page/blog-page.component';
import { CommentComponent } from '../comment/comment.component';
import { ReactiveFormsModule, FormsModule } from '@angular/forms';

@NgModule({
    declarations: [
        BlogComponent,
        BlogPageComponent,
        CommentComponent
    ],
    imports: [
        CommonModule,
        ReactiveFormsModule,
        FormsModule,
        RouterModule.forChild([{
            path: 'blog',
            component: BlogComponent
        },
        {
            path: 'blog/:title',
            component: BlogPageComponent
        }
        ])
    ],
    providers: [],
    bootstrap: [
        BlogComponent
    ],
    exports: [

    ]
})
export class BlogModule {

}

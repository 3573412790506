<div class="book-single-container">

  <div class="book-banner" [id]="_translationsService.getLocal() === 'ru' ? 'howToFixEmtinessBanner' : 'howToFixEmtinessBannerEng'">
  </div>
  <div class="book-about">
    <p>
      {{_translationsService.getTranslations().books.shortDescriptions.howToFixEmptiness}}
    </p>
  </div>
  <a class="litres-link" href="https://www.litres.ru/ira-shilova/kak-ispravit-pustotu/" target="_blank">
    <img src="../../../assets/litres.webp" alt="litres" width="64" height="64">
    <i class="shop-link" *ngIf="_translationsService.getLocal() === 'en'">Russian edition only here</i>
    <i class="shop-link" *ngIf="_translationsService.getLocal() === 'ru'">Купить</i>
  </a>
  <div class="read-header" *ngIf="_translationsService.getLocal() === 'ru'">
    <h1>1 глава</h1>
    <h2>
      Побег из дома на поиски Жар-Птицы
    </h2>
  </div>
  <div class="read-content" *ngIf="_translationsService.getLocal() === 'ru'">
    <p>
      Ян и Марк Горечавка собирались в поход.
      – Глянь, я всё взял? – попросил Ян брата и раскрыл пошире тряпичный рюкзак. – Тут фонарик, спички, нитки, два
      пластыря, теннисный мяч и пакет сухариков.
      – Воду забыл.
      – Точно! На кухне оставил. Я мигом.
    </p>
    <p>
      Ян вытер ладонью под носом, выскользнул из комнаты и исчез в тёмном коридоре. Марк взглянул на пустой дверной
      проём, вздохнул и лениво повернулся к распахнутому окну. Утреннее солнце ослепило мальчика, но он даже не
      моргнул.
      Марк рассматривал овсянок, которые прыгали по веткам берёз, щебетали, задирали друг дружку, и не щурился, хоть
      яркие солнечные лучи щипали глаза.
    </p>
    <p>
      – Повезло! – сказал Ян, подкинув на ладони бутылку минералки. – Мама и папа ещё спят.
      Марк не повернулся. Он ответил:
      – Они стали слишком часто забываться сном.
      – Повезло, – повторил Ян и затолкал бутылку в рюкзак, – убежим незаметно.
      – Ты ещё не передумал?
    </p>
    <p>
      Ян хотел уже ответить, но замешкался. Мальчик осмотрел их с братом комнату, столь непривычно чистую, аккуратную,
      залитую тёплым летним светом. Так было бы приятно остаться в ней и весь день провести за настольными играми. Или
      сесть за приставку и бить рекорды друг друга в гоночках. Вечером устроить бой подушками. А на ночь соорудить
      шалаш
      из простыней. Но мальчик посмотрел на брата, вытер под носом и ответил:
    </p>
    <p>
      – Не передумал. Я докажу тебе, что она существует. Даже если ради этого придётся пропустить подушечный обстрел в
      гостиной.
      – Как хочешь.
      Ян закинул на плечо рюкзак, попрыгал с ним, потоптался на месте и заметил:
      – Лёгкий какой-то. Может, ещё что-нибудь взять? Куртки? Свитера? Жилетки?
    </p>
    <p>
      – Взгляни на небо. Солнце стопроцентное. Жарит даже ночью.
      Ян пожал плечами и внимательно осмотрел сначала себя, а затем – брата. Ребята не любили одеваться в одинаковую
      одежду. Это делало их, близнецов Горечавка, по-дурацки «безотличными». Но сейчас такая крайняя мера была просто
      необходима. Братья шли в опасный поход и хотели всем своим видом напоминать друг другу о взаимовыручке. Они одно
      целое и только вместе найдут ответ.
    </p>
    <p>
      – Майку поправь, – подметил Ян и указал брату на задравшийся край белой одежды.
      – А ты – штанину.
      – Обувь у тебя?
      – Ещё вчера под кроватью спрятал.
      Братья быстро надели кеды. Марк сел на подоконник, развернулся носом к улице и свесил ноги.
      – Ну, ты идёшь?
      – Сейчас, – шикнул Ян на брата. – Нельзя же оставлять следов.
      Мальчик разгладил складки на кроватях, поправил коврик, а потом сел рядом с Марком, задёрнул за собой занавески
      и
      сказал:
      – Давай. Ты первый.
    </p>
    <p>
      Близнец послушно спрыгнул на траву.
      – А теперь помоги мне.
      Ян повис, упёрся кедами брату в плечи, пальцами подтянул оконные рамы, сомкнул их и проговорил:
      – Вот. Закрыл. Теперь никто не догадается, что мы вылезли в окошко.
      Мальчик легко соскочил на землю, вытер под носом и покрутил на руке маленький браслет. Ян повернулся к близнецу
      и
      заметил, что тот уже шёл к ограде дома.
      – Эй! Подожди меня.
      Братья Горечавка шустро перелезли через каменный забор. Они часто так делали, чтобы посоревноваться в ловкости,
      хотя родители просили их пользоваться воротами.
      Марк и Ян шли прочь от пригородного посёлка, в котором они жили всю жизнь. Ребята направлялись в место
      малоизученное, почти незнакомое. Они волновались, но признаваться в этом не хотели.
    </p>
    <p>
      – Ян, зря мы телефоны оставили.
      – Не зря.
      – А если потеряемся?
      – Не потеряемся.
      Марк напомнил:
      – Да ты прошлом году забыл, как от кинотеатра к дому вернуться.
      – Так это в прошлом году было. Теперь я старше. Двенадцать лет – почти старость.
      – Сказал младший брат.
    </p>
    <p>
      Яна ужас как задевала эта фразочка близнеца. Да, Марк родился раньше на полчаса. Но напоминать об этом – гадкий
      приём. Ян не дразнил же брата за то, что он позже просыпается.
      – Посмотрим, кто из нас почувствует себя младшим, когда я докажу, что она существует, – заметил Ян и пошёл по
      асфальтированной дороге быстрее.
      – Чудес не бывает.
      – Ещё как бывает. Я уверен.
      – Ну-ну, упрямься. Правильно. Главное, настрой не растеряй к той счастливой неделе, когда будешь кровать
      заправлять за мной.
    </p>
    <p>
      – Хо-хо, это тебе придётся ухаживать за моей постелькой, как только полюбуешься на Жар-Птицу.
      Марк ускорил шаг, нагнал брата и сказал:
      – Никто в мире её не видел.
      – И что?
      – А то, что люди уже научились роботов делать, а ты всё в сказки веришь.
      Ян не унимался:
      – Ну, если люди чудеса вытворяют, то почему бы и птице не научиться делать что-то похожее?
    </p>
    <p>
      Старший близнец вздохнул:
      – У птиц мозги нечеловеческие.
      Асфальтированная дорога упёрлась в невысокий деревянный забор. За ограждением начинались «дикие земли». Так
      братья
      назвали лес, который рос бесконечным и еловым ещё задолго до появления посёлка. Ян и Марк не увлекались раньше
      этим разросшимся океаном деревьев и в нём не гуляли. Да и взрослые сделали всё, чтобы в лес ходить не хотелось:
      поставили забор, назвали место охраняемым, повесили табличку «штраф пятьдесят тысяч рублей». А у братьев из всех
      сбережений только копилка с мелочью, да пара редких наклеек.
    </p>
    <p>
      – Надеюсь, – Марк указал пальцем на знак, – у тебя есть столько денег.
      – Пф, да кто нам штраф в лесу-то выпишет? Дятел?
      Ян снял рюкзак, замахнулся и перебросил его через забор. Старший брат, не упуская возможности покрасоваться,
      потёр
      ладони об колени, согнулся и резко прыгнул. Он схватился за край забора, подтянулся и сел на него верхом.
      – Ну, как там?
      Марк ответил:
      – Да ничего не вижу. Солнце слепит.
      – Есть там кто?
      – Кажется, нет.
      Ян ухватился за дощечки, выпрямился на руках и ахнул:
      – Ничего себе. Точно «дикие земли».
    </p>
    <p>
      Верхушка леса утопала в золотом свете, а мелкая сеточка солнечных лучей блестела на боках деревьев. С ветки на
      ветку шныряли птички. Густое благоухание хвои кружило на тёплом ветерке. От забора, на котором, как две цапли,
      взгромоздились братья, тянулись пёстрой скатертью к лесу травы и полевые цветы.
      – Этот лес отлично подходит для Жар-Птицы. Он же просто сказочный! – заметил Ян и спрыгнул с забора на
      неизведанные «дикие земли».
      – В этот лес люди не заходят. Вот это точно сказочно.
      Марк быстро посмотрел на спящий посёлок, словно ждал окриков взрослых, а потом соскочил на землю к брату.
      Младший
      близнец нацепил рюкзак, поправил лямки, грохнул:
      – Кто последний – тот столетний!
      И мальчик помчался к лесу так быстро, что только листья клевера да лепестки ромашек вылетали из-под его ног.
      Марк
      побежал за братом и выкрикнул:
      – А кто жулит, тот не рулит!
    </p>
  </div>
  <div class="read-header" *ngIf="_translationsService.getLocal() === 'ru'">
    <i>Конец ознакомительного отрывка</i>
    <div class="stories-banner" id="howToFixEmtinessStoriesBanner"></div>
    <div class="stories-annonce">
      <p>
        А здесь можно почитать истории, которые приключились с братьями до того, как они отправились в долгое
        приключение.
      </p>
    </div>
  </div>
  <div *ngIf="_translationsService.getLocal() === 'ru'">
    <div class="stories-container" *ngIf="stories | async as stories">
      <div class="single-story-view" (click)="openStory(story.sys.id)" *ngFor="let story of stories.items">
        <img class="single-story-img-min" :src="{{story.fields.imagePreview.fields.file.url}}" height="100px"
          width="100px" alt="category" />
        <p>{{story.fields.name}}</p>
      </div>
    </div>
  </div>
</div>

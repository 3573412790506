import { Component, OnInit } from '@angular/core';
import { TranslationsService } from '../../translations/translations.service'
import { Router } from '@angular/router';
import { MetaTitleService } from 'src/app/meta-title.service';

@Component({
  selector: 'app-book-ultramarin',
  templateUrl: './book.ultramarin.component.html',
  styleUrls: ['./book.ultramarin.component.scss']
})
export class BookUltramarinComponent implements OnInit {

  constructor(public _translationsService: TranslationsService, private _router: Router, private metaTitleService: MetaTitleService) {
  }

  ngOnInit() {
    this.metaTitleService.updateTitle('Ультрамарин');
    this.metaTitleService.updateTag('description', 'Купить или читать рассказ Ультрамарин автора Иры Шиловой.');
  }
}

import { Component, OnInit } from '@angular/core';
import { TranslationsService } from '../../translations/translations.service';
import { Router } from '@angular/router';
import { MetaTitleService } from 'src/app/meta-title.service';
import { ContentfulService } from 'src/app/blog/contentful.service';

@Component({
  selector: 'app-book',
  templateUrl: './book.howToFixEmptiness.component.html',
  styleUrls: ["./book.howToFixEmptiness.component.scss"]
})
export class BookHowToFixEmptinessComponent implements OnInit {

  stories;

  constructor(public _translationsService: TranslationsService, private _router: Router, private metaTitleService: MetaTitleService, private _service: ContentfulService) {
  }

  ngOnInit() {
    this.stories = this._service.getStories();
    this.stories.subscribe(() => {
    });
    this.metaTitleService.updateTitle('Как исправить пустоту');
    this.metaTitleService.updateTag('description', 'Книга Как исправить пустоту. Автор Ира Шилова.');
  }

  openJanMarkStories() {
    this._router.navigate(['history/janMark'], { skipLocationChange: true });
  }

  openStory(id) {
    this._router.navigate(['/history/janMark/story/', id], { skipLocationChange: true });
  }
}

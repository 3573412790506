import { Injectable } from '@angular/core';
import { v4 as uuidv4 } from 'uuid';

@Injectable({
  providedIn: 'root'
})
export class UserIdService {
  private userIdKey = 'user-id';

  constructor() {
    this.initializeUserId();
  }

  private initializeUserId(): void {
    if (!localStorage.getItem(this.userIdKey)) {
      localStorage.setItem(this.userIdKey, uuidv4());
    }
  }

  getUserId(): string {
    return localStorage.getItem(this.userIdKey);
  }
}

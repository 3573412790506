import { Component, OnInit } from '@angular/core';
import { TranslationsService } from '../../../translations/translations.service';
import { Router } from '@angular/router';
import { MetaTitleService } from 'src/app/meta-title.service';

@Component({
  selector: 'app-book-parents',
  templateUrl: './how-to-save-parents-read.component.html',
  styleUrls: ['./how-to-save-parents-read.component.scss']
})
export class HowToSaveParentsReadComponent implements OnInit {

  constructor(public _translationsService: TranslationsService, private metaTitleService: MetaTitleService) { }

  ngOnInit() {
    this.metaTitleService.updateTitle('Как спасти родителей');
    this.metaTitleService.updateTag('description', 'Читать книгу Как спасти родителей. Автор Ира Шилова.');
  }

}

<div class="book-single-container">

  <div class="book-banner"
    [id]="_translationsService.getLocal() === 'ru' ? 'howToSaveParentsBanner' : 'howToSaveParentsBannerEng'">
  </div>
  <div class="book-about">
    <p>
      {{_translationsService.getTranslations().books.shortDescriptions.howToSaveParents}}
    </p>
  </div>
  <div class="book-trailer">
    <iframe src="https://www.youtube.com/embed/1sKzVc5t9WQ" frameborder="0" allowfullscreen></iframe>
  </div>
  <a class="litres-link" href="https://www.litres.ru/ira-shilova/kak-spasti-roditeley/" target="_blank">
    <img src="../../../assets/litres.webp" alt="litres" width="64" height="64">
    <i class="shop-link" *ngIf="_translationsService.getLocal() === 'en'">Russian edition only here</i>
    <i class="shop-link" *ngIf="_translationsService.getLocal() === 'ru'">Купить</i>
  </a>
  <div *ngIf="_translationsService.getLocal() === 'ru'">
    <div class="read-header">
      <h2> 1 глава</h2>
      <h3>Где Ухо?</h3>
    </div>
    <div class="read-content">
      <div>
        – Ну уж нет, это тоже не поможет, – сказал Виктор и выбросил кленовый лист.
        – Как мы тебе это к уху прикрепим? Кажется, так и будешь ходить дырявым.
      </div>

      <p>Пёсик фыркнул, высунул язык и побежал искать палочки.</p>
      <p>
        Виктор продолжил высматривать листья, которые подошли бы его другу. Мальчик, как настоящий взрослый хозяин,
        хотел помочь пёсику вернуть кусочек уха. А то где это видано, чтобы псы ходили неполными после драки с крысой?
        Получить имя за такую страшную битву — это можно, да. Ухо любил свою кличку. Но звериное благородство и
        красоту нужно вернуть обязательно.
      </p>

      <p>
        – Сегодня утром холодно, – сказал Виктор Уху, – папа просил, чтобы мы гуляли недолго. Поэтому давай дойдём до
        того знака в виде арбузной дольки и обратно, ладно?
      </p>
      <p>
        Свистели птицы, которые остались на зимовку. Пахло сухими листьями. Виктор по-черепашьи шёл вдоль железной
        дороги, а коротколапый пёсик вертелся юлой вокруг хозяина.
      </p>
      <p>
        – Хлоп! – дотронулся Виктор до столба, на котором висел знак. – Дошли. Я тебя выгулял. Смотри, где город. Так
        далеко, да? Ух? Ухо, ты где?
      </p>
      <p>
        Мальчик покрутился, осмотрел кусты возле столба, проверил заросли сухой травы, но пёсика не нашёл.
      </p>
      <p> – Ух? – Виктор встал на рельсы. – Ух!</p>
      <p> Пёсик не отзывался.</p>
      <p>
        – Ну уж нет! – Сказал мальчик, когда увидел, как Ух мелькнул белыми лапками и исчез в лесу, который рос по
        другую сторону железной дороги. Мама и папа запрещали Виктору гулять среди гигантских деревьев, колючих кустов и
        чёрных мхов. Да мальчик и сам не хотел лезть в этот старый лес. Виктору хватало парков. Поэтому мальчик решил не
        идти за пёсиком, а просто громче позвать его.
      </p>
      <p>
        – Вернись! Ну пожалуйста! – Виктор сложил пальцы трубочкой возле рта. – Вернись!
      </p>
      <p>
        Пёсик не откликался. Он явно нашёл что-то занимательное.
      </p>
      <p>
        – Ну уж нет, я не пойду за тобой! – Виктор поправил шапку, почесал пухлую щёку, спрятал руки в карманах куртки.
        – Сам домой вернёшься, когда надоест гонять лесных крыс.
      </p>
      <p>
        Виктор зашагал вдоль железной дороги обратно к дому. Сначала мальчик шёл быстро, затем — чуть медленнее, а потом
        и вовсе остановился. Виктор посмотрел через плечо на лес. Древние деревья стояли так близко друг к другу, что их
        можно было принять за забор. И где-то за этой оградой потерялся Ух.
      </p>
      <p>
        Мальчик тяжело вздохнул, крепко схватил себя за локти и перешагнул сначала через одну рельсу, затем через
        вторую.
      </p>
      <p>
        – Ну уж нет, – бормотал Виктор себе под нос, – я хозяин и друг. Мне нельзя возвращаться домой одному.
        А ещё через три шага Виктор уже стоял возле леса, который пах холодными травами.
      </p>
      <p>
        – Гуляли вдоль железки много раз, – продолжал говорить мальчик, – и всегда всё было хорошо. Никто никуда не
        убегал, мы возвращались домой, рассказывали, что видели, мыли лапы... Уху мыли, не мне, конечно. Прятали в
        коробку сокровищ новые палочки с улицы. Потом смотрели мультики. Мама готовила печенье с шоколадом и корицей.
        Хрустящее и ароматное. Вечером папа читал со мной комиксы. И всем такое расписание нравилось. Вот найду Уха и
        скажу ему, чтобы больше ничего не менял.
      </p>
      <p>
        Виктор разговаривал сам с собой и даже не заметил, как зашёл в лес. Пение птиц-домоседок становилось тише.
        Солнце почти не дотягивалось до жёлтой шапки мальчика. Виктор впервые оказался в незнакомом месте один.
        Но к огромной радости мальчика вдруг раздалось фырканье Уха. Пёсик не умел лаять и на всё интересное или опасное
        пыхтел, как маленький паровоз.
      </p>
      <p>
        – Ух!
      </p>
      <p>
        Виктор перевалился через колючий куст и оказался на полянке. На ней росло единственное дерево-великан, которое
        заинтересовало Уха. Пёсик копал землю между его корней.
      </p>
      <p>
        – Ух! Я же тебя звал. Ты чего не вернулся? Нам нельзя сюда заходить, потеряемся. Идём, я найду тебе палку и
        отведу домой мыть лапы.
      </p>
      <p>
        Но как бы мальчик не уговаривал, Ух продолжал увлечённо раскапывать корни дерева и фыркать.
      </p>
      <p>
        Виктор вздохнул, подтянул шарф и нагнулся к пёсику, чтобы оттащить его в сторону. Мальчик поднял Уха, и так с
        ним и застыл. Потому что Виктор вдруг услышал, как кто-то тихо сказал:
      </p>
      <p>
        – Помоги мне.
      </p>
    </div>
    <div class="read-container">
      <div class="read-header">
        <h3>2 глава</h3>
        <h2 style="text-align: center;">Девчонка с дерева и неприятности.</h2>
      </div>
    </div>
    <div class="read-content">
      <p>
        Мальчик крепко прижал Уха к себе и осмотрелся по сторонам. Кто-то повторил:
      </p>
      <p>
        – Пожалуйста, помоги мне.
      </p>
      <p>
        Виктор застыл и притворился растением. А вдруг повезёт, и незнакомец отстанет?
      </p>
      <p>
        – Мальчик, пожалуйста. Я здесь.
      </p>
      <p>
        Ух устал висеть на руках хозяина, подтянул лапки, согнулся и прыгнул на землю.
      </p>
      <p>
        – Ай! Ты нас выдал!
      </p>
      <p>
        – Мальчик, сюда.
      </p>
      <p>
        Виктор вздрогнул и опасливо посмотрел вверх. На ветвях дерева сидела девочка.
      </p>
      <p>
        – Поможешь мне слезть? – Помахала она рукой.
      </p>
      <p>
        – Ты же туда как-то залезла? Вот и спускайся сама, – отмахнулся Виктор и потащил Уха за задние лапы прочь от
        корней дерева.
      </p>
      <p>
        – Я не залезала. Папа меня здесь спрятал.
      </p>
      <p>
        – Спрятал? – Виктор снова посмотрел на девочку. – Ну уж нет, тогда точно не буду помогать. Лучше побыстрее сам
        вернусь домой.
      </p>
      <p>
        Девочка продолжала:
      </p>
      <p>
        – Пожалуйста-пожалуйста! Я не знаю, когда папа вернётся, а мне ужасно скучно. Если слезть с дерева, можно хотя
        бы желуди пособирать.
      </p>
      <p>
        – А ты срывай их прямо с веток.
      </p>
      <p>
        – С земли подбирать интереснее. Так можно ещё какие-нибудь сокровища в траве найти.
      </p>
      <p>
        Виктор тянул Уха на себя, но пёсик словно прирос к земле и не двигался. Мальчик хотел поскорее оставить позади
        приставучую девчонку с дерева, выйти из леса, а дома попить чая с печеньем. Виктор тянул Уха и тянул. Тащил и
        тащил.
      </p>
      <p>
        – Пойдём домой. Пойдём. Быстрее. Ой!
      </p>
      <p>
        Виктор шлёпнулся на землю в обнимку с пёсиком. Ух фыркал и показывал хозяину, какой замечательный корень он
        выкопал из-под земли. Но Виктор не обращал внимания на друга. Мальчик широко открыл рот и вытаращил глаза — на
        дереве, от самой земли до высоких ветвей, мгновенно выросли ступеньки.
      </p>
      <p>
        – Ура-ура! – Захлопала в ладоши девочка. – Как ты узнал? Это тот самый тайный корень! Сейчас спущусь!
      </p>
      <p>
        – Стой! Не надо!
      </p>
      <p>
        Но Виктор не успел остановить девчонку. Она быстро перебралась на ступеньки и смело побежала вниз.
      </p>
      <p>
        – Привет! Я Дана.
      </p>
      <p>
        Мальчик поджал губы и сделал шаг назад. Девчонка с дерева выглядела, как самая обычная девчонка. Только слишком
        уж летняя — в платье с рукавами и босиком.
      </p>
      <p>
        – Привет и пока. Мы пошли.
      </p>
      <p>
        Виктор поднял Уха с его новой палочкой-корнем и покосился на девчонку. Она улыбалась, но грустно.
      </p>
      <p>
        – Что?
      </p>
      <p>
        – А как тебя зовут?
      </p>
      <p>
        – Виктор.
      </p>
      <p>
        – А это Ух, да? Я слышала, как ты его звал. А сколько тебе лет? Мне одиннадцать.
      </p>
      <p>
        – Десять, – Виктор сделал ещё шаг назад. Девчонка была не только странная, но и старше его на целый год. – Нам
        пора домой.
      </p>
      <p>
        Дана похлопала рукой по сумке, которая висела у неё через плечо, и спросила:
      </p>
      <p>
        – А желуди?
      </p>
      <p>
        – Это тебе они нужны. Да и дерево-то чудно́е, на нём ступеньки выросли. Не советую под ним что-то собирать.
      </p>
      <p>
        – А как же новая палочка Уха? Она тоже чудна́я?
      </p>
      <p>
        Мальчик сделал вид, что не услышал Дану. Виктор покрепче прижал к себе Уха и пошёл в сторону колючих кустов.
        Мальчику не нравились незнакомые места и умные девчонки. И уж тем более ему не нравились умные девчонки в
        незнакомых местах.
      </p>
      <p>
        Виктор поправил шарф, чтобы не зацепиться им за колючки, и хотел уже оставить полянку позади, как вдруг раздался
        мужской голос:
      </p>
      <p>
        – Зачем ты потревожил мою дочь?
      </p>
      <p>
        Мальчик сделал три быстрых шага назад и вернулся под солнечные лучи. Из темноты леса на Виктора смотрели
        светящиеся белые глаза.
      </p>
      <p>
        – Зачем ты потревожил мою дочь? – Голос звучал, как мотор старой машины.
        Виктор ещё крепче сжал пёсика в руках.
      </p>
      <p>
        – Отвечай.
      </p>
      <p>
        – Я? – Виктор не узнал собственный голос. – Я ничего не делал. Ух просто взял себе игрушку.
      </p>
      <p>
        – Ничего не делал? Дана, подойди.
      </p>
      <p>
        Девочка опустила голову, спрятала руки за спиной и подошла к деревьям. Виктор не понимал, как так получалось,
        что девочку в лесу он видит, а её отца — нет. Мальчик различал только грозный силуэт взрослого и круглые белые
        глаза.
      </p>
      <p>
        – Прости, папа. Мне стало скучно. Я спустилась вниз.
      </p>
      <p>
        – Видите? – Виктор хотел скорее всё прояснить. – Она сама слезла с дерева.
      </p>
      <p>
        – Неправда, – загудел голос. – Ты ей помог. Дана теперь не в безопасности. Ты вмешался в мою семью, а значит
        теперь моя очередь. Я могу найти твой дом и навсегда забрать оттуда твоих маму и папу.
      </p>
      <p>
        Виктор почувствовал, как у него задрожали коленки. Мальчик скукожился и постарался не упасть. Ответить злобному
        Чужаку из леса Виктору не хватало сил.
      </p>
      <p>
        – Но я дам тебе шанс спасти свою семью. Тебе нужно исправить то, что ты разрушил — сделай жизнь Даны снова
        защищённой.
      </p>
      <p>
        – Папа, я уже взрослая и могу сама...
      </p>
      <p>
        – Тихо, Дана. Ты должна быть в безопасности, помнишь? – Чужак впервые за долгое время моргнул. – Так вот,
        мальчик, реши до захода солнца этот вопрос. Иначе ты больше никогда не увидишь родителей. – Чужак помолчал. – С
        недавних времён я больше не доверяю людям. Поэтому буду следовать за тобой по пятам и, если догоню, это плохо
        для тебя кончится.
      </p>
      <p>
        Виктор беззвучно двигал бледными губами. Мальчик не мог понять, за что его наказывают? И как это, больше никогда
        не увидеть маму с папой?
      </p>
      <p>
        Чужак медленно втянул воздух и сказал:
      </p>
      <p>
        – Начинай, мальчик. Десять, девять, восемь...
      </p>
      <p>
        Ух на руках Виктора заволновался. Пёсик начал фыркать, дёргать лапками, лизать щёки хозяина. Виктор зажмурился
        крепко-крепко, а потом широко раскрыл глаза. Но лес не пропал. Светящиеся белые глаза всё ещё таращились из
        темноты. Гудел ужасный голос: «шесть, пять...».
      </p>
      <p>
        Перед глазами Виктора возникла картина: он прибегает домой, объясняет маме и папе, что они в опасности, все
        расстраиваются, волнуются, потом решают укрыться у бабушки в центре города, печенье, мультики и комиксы
        отменяются. Но самое страшное в этом образе то, что родители навсегда разочаровываются в сыне. Ведь он подверг
        семью смертельной опасности.
      </p>
      <p>
        Мальчик понял, что домой ему не вернуться. Виктор горько всхлипнул и побежал прочь от фигуры с белыми глазами. А
        за спиной мальчика раздавалось «три, два, один...».
      </p>
    </div>
    <div class="read-header">
      <i>Конец ознакомительного отрывка</i>
    </div>
    <a class="litres-link" href="https://www.litres.ru/ira-shilova/kak-spasti-roditeley/" target="_blank">
      <img src="../../../assets/litres.webp" alt="litres" width="64" height="64">
      <i class="shop-link">Купить</i>
    </a>
  </div>
</div>

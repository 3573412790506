import { Component, OnInit } from '@angular/core';
import { TranslationsService } from '../../translations/translations.service'
import { Router } from '@angular/router';
import { MetaTitleService } from 'src/app/meta-title.service';

@Component({
  selector: 'app-book-neesh',
  templateUrl: './book.neesh.component.html',
  styleUrls: ['./book.neesh.component.scss']
})
export class BookNeeshComponent implements OnInit {

  constructor(public _translationsService: TranslationsService, private _router: Router, private metaTitleService: MetaTitleService) {
  }

  ngOnInit() {
    this.metaTitleService.updateTitle('Не ешь меня');
    this.metaTitleService.updateTag('description', 'Купить или читать сказку Не ешь меня автора Иры Шиловой.');
  }
}

import { Injectable } from '@angular/core';
import { AngularFirestore } from '@angular/fire/compat/firestore';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})
export class CommentService {
  constructor(private firestore: AngularFirestore) {}

  addComment(postId: string, comment: Comment) {
    const commentsRef = this.firestore.collection(`posts/${postId}/comments`);
    return commentsRef.add(comment);
  }

  getComments(postId: string): Observable<Comment[]> {
    return this.firestore.collection<Comment>(`posts/${postId}/comments`, ref =>
      ref.orderBy('timestamp', 'desc')
    ).snapshotChanges().pipe(
      map(actions => actions.map(a => {
        const data = a.payload.doc.data() as Comment;
        const id = a.payload.doc.id;
        return { id, ...data };
      }))
    );
  }
}


export interface Comment {
  id: string,
  key?: string;
  //userId: string,
  name: string;
  email: string;
  comment: string;
  moderated: boolean;
  timestamp: string;
  postTitle: string;
}

<div class="book-single-container">

  <div class="book-banner" [id]="_translationsService.getLocal() === 'ru' ? 'eternityBanner' : 'eternityBannerEng'">
  </div>
  <div class="book-about">
    <p>
      {{_translationsService.getTranslations().books.shortDescriptions.eternity}}
    </p>
  </div>
  <a class="litres-link" href="https://www.litres.ru/book/ira-shilova/minuya-propast-napravlyatsya-v-vechnost-69511672/"
    target="_blank">
    <img src="../../../assets/litres.webp" alt="litres" width="64" height="64">
    <i class="shop-link" *ngIf="_translationsService.getLocal() === 'ru'">Купить</i>
    <i class="shop-link" *ngIf="_translationsService.getLocal() === 'en'">Russian edition only here</i>
  </a>
  <div *ngIf="_translationsService.getLocal() === 'ru'">
    <div class="read-content">
      <p>
        Когда-то давным-давно по этим землям протекала река, а над ней изгибался мост. Не помню, кто догадался
        перебросить
        через быстрые ледяные воды каменную арку. Люди? Гномы? А, может, мост неким чудом сам добрался до здешних краёв?
        Забыла. Да это и неважно. Важно то, что мост был отличный, и ничьих ожиданий никогда не обманывал. Он стоял
        точно
        там, где нужно, точно так, как удобно. Странники путешествовали по нему часто и мирно, ласточки строили гнёзда
        под
        его несокрушимым сводом, а цветы серебристой камнеломки разукрашивали валуны диорита наподобие роскошного ковра.
        И
        как это водится, под таким хорошим и нужным мостом однажды завёлся тролль.
      </p>
      <p>
        Нареку его просто Троллем, поскольку имя он себе не придумал. Тролль жил у реки тихо и честно, ловил рыбу, умело
        её потрошил и ещё искуснее поджаривал на костре. А вот если бы Тролль пошёл по стопам своих мамы да папы, и
        начал
        бы взимать плату за проход по мосту, то вместе с родичами безвременно и окончательно бы почил. Но Тролль
        никакого
        интереса к путникам не проявлял, мост своим не объявлял, занимался любимым делом и одиночеством не терзался.
        Ведь
        к чудищу на рыбалку прибегал из людской деревни Мальчик.
      </p>
      <p>
        Мальчик, отважный сердцем и неукротимый духом, по незнанию нарушал законы человеческих селений, которые гласили:
        «Не якшаться с чудищами (они сильны, а всё сильное нахраписто и внезапно)», «Не ходить поодиночке (ибо только на
        глазах другого человека человек ведёт себя достойно)», «Не удить, не есть рыб (они невкусные, заботливые пращуры
        в
        этом лично убедились)». Род людской в те времена был так молод, что подобные глупости принимал за совершенство
        мысли. Но Мальчик, самый юный человечек из самого юного сообщества на Земле, вере на слово ещё не научился. А
        потому он не боялся Тролля, не опасался реки, не взвизгивал, когда из холодных вод вытягивал чешуйчатые тушки
        рыб,
        и был счастливейшим ребёнком.
      </p>
      <p>
        Мальчик искренне любил Тролля. Удивительно огромное и поразительно доброе чудище никогда не поучало, не
        надзирало,
        не отчитывало за дурашливость, а как известно, с таким другом мир познавать куда приятней и проще. Тролль же
        безгранично ценил Мальчика за то, что человечек развеивал дурные мысли о ненужности и одиночестве. Ведь когда ты
        последний живой член семейства, хоть и принимающий это знание с мудрой рассудительностью, ты всё равно иногда,
        эпизодически, тайком от солнца и луны, чуточку грустишь. А в пасмурные дни так вовсе унываешь. Однако если
        огромный мир, который сделал тебя чудищем, да ещё и сиротой, излечивает раны дружбой, то жить становится
        интереснее. И задорнее. От непрерывного щебета парнишки, его вопросов и стремления поймать все виды рыб, в
        огромной голове монстра расцветали невероятные модели новых удочек. Тролль смастерил для Мальчика тридцать три
        вида катушек, девять десятков крючков и необъятное собрание разномастных удилищ. А друг для друга чудище и
        человек
        готовили на углях рыбу, сдобренную тимьяном, диким перцем да кислицей; и вместе неторопливо ужинали, приглядывая
        за непрерывным течением времени.
      </p>
    </div>
    <a class="litres-link"
      href="https://www.litres.ru/book/ira-shilova/minuya-propast-napravlyatsya-v-vechnost-69511672/" target="_blank">
      <img src="../../../assets/litres.webp" alt="litres" width="64" height="64">
      <i class="shop-link">Купить</i>
    </a>
  </div>
</div>

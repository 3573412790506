import { Component, OnInit } from '@angular/core';
import { TranslationsService } from '../../translations/translations.service';
import { MetaTitleService } from 'src/app/meta-title.service';
import { ActivatedRoute } from '@angular/router';
import { RemoteDataService } from 'src/app/remote-data.service';

@Component({
  selector: 'app-illustration-category',
  templateUrl: './illustration-category.component.html',
  styleUrls: ['./illustration-category.component.scss']
})
export class IllustrationCategoryComponent implements OnInit {

  illustrations = [];
  showSpinner = true;
  category = null;

  currentIllustrationInfo = {
    file: '',
    description: '',
    descriptionEng: '',
    size: '',
    tool: '',
    toolEng: ''
  };

  isNext = true;
  isPrevious = true;

  constructor(public _translationsService: TranslationsService, private metaTitleService: MetaTitleService,
    private _activeRouter: ActivatedRoute, private remoteDataService: RemoteDataService ) {

  }

  ngOnInit() {
    this._activeRouter.params.subscribe(params => {
      switch (params.id) {
        case 'emptiness': this.category = 1; this.illustrations = this.remoteDataService.getIllustrations().emptiness; break;
        case 'save-parents': this.category = 2; this.illustrations = this.remoteDataService.getIllustrations().saveParents; break;
        case 'histories': this.category = 3; this.illustrations = this.remoteDataService.getIllustrations().histories; break;
        default: this.category = 3;
      }
      const backdrop = <HTMLFormElement>document.getElementsByClassName('modal-backdrop')[0];
      if (backdrop !== undefined) {
        backdrop.remove();
      }
    });

    this.metaTitleService.updateTitle('Иллюстрации');
    this.metaTitleService.updateTag('description', 'Иллюстрации Иры Шиловой');
  }

  openModal(illustration) {
    this.currentIllustrationInfo.file = illustration.image.fields.file.url;
    this.currentIllustrationInfo.description = illustration.description;
    this.currentIllustrationInfo.descriptionEng = illustration.descriptionEng;
    this.currentIllustrationInfo.tool = illustration.tool;
    this.currentIllustrationInfo.toolEng = illustration.toolEng;
    this.currentIllustrationInfo.size = illustration.size;

    const modal = document.getElementById('img-wrap');
    modal.classList.remove('hide');

  }

  previousIllustration() {
    this.illustrations.forEach((illustration, index) => {
      if (this.currentIllustrationInfo.description === illustration.description) {
        if (index === 0) {
          return;
        }
        this.currentIllustrationInfo.file = this.illustrations[index - 1].image.fields.file.url;
        this.currentIllustrationInfo.description = this.illustrations[index - 1].description;
        this.currentIllustrationInfo.descriptionEng = this.illustrations[index - 1].descriptionEng;
        this.currentIllustrationInfo.tool = this.illustrations[index - 1].tool;
        this.currentIllustrationInfo.toolEng = this.illustrations[index - 1].toolEng;
        this.currentIllustrationInfo.size = this.illustrations[index - 1].size;
      }
    });
  }

  nextIllustration() {
    let found = false;
    this.illustrations.forEach((illustration, index) => {
      if (this.currentIllustrationInfo.description === illustration.description && !found) {
        if (index === this.illustrations.length - 1) {
          return;
        }
        this.currentIllustrationInfo.file = this.illustrations[index + 1].image.fields.file.url;
        this.currentIllustrationInfo.description = this.illustrations[index + 1].description;
        this.currentIllustrationInfo.descriptionEng = this.illustrations[index + 1].descriptionEng;
        this.currentIllustrationInfo.tool = this.illustrations[index + 1].tool;
        this.currentIllustrationInfo.toolEng = this.illustrations[index + 1].toolEng;
        this.currentIllustrationInfo.size = this.illustrations[index + 1].size;
        found = true;
      }
    });
  }

  closeModal() {
    const modal = document.getElementById('img-wrap');
    modal.classList.add('hide');
  }
}

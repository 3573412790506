import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class SubscriptionService {

  private isSubscribed = false;

  constructor() {
    if (localStorage.getItem('isSubscribed') == undefined || localStorage.getItem('isSubscribed') == null) {
      this.isSubscribed = false;
      localStorage.setItem('isSubscribed', '' + this.isSubscribed);
    } else {
      this.isSubscribed = JSON.parse(localStorage.getItem('isSubscribed'));
    }
   }

   public getIsSubscribed() {
    if (JSON.parse(localStorage.getItem('isSubscribed')) == undefined) {
      this.isSubscribed = false;
      return this.isSubscribed;
    }
    this.isSubscribed = JSON.parse(localStorage.getItem('isSubscribed'));
    return this.isSubscribed;
  }

  setIsSubscribed(isSubscribed: boolean) {
    this.isSubscribed = isSubscribed;
    localStorage.setItem('isSubscribed', JSON.stringify(isSubscribed));
  }
}

import { Component, OnInit } from '@angular/core';
import { TranslationsService } from '../translations/translations.service';
import { Router } from '@angular/router';
import { MetaTitleService } from '../meta-title.service';

@Component({
  selector: 'app-book',
  templateUrl: './book.component.html'
})
export class BookComponent implements OnInit {

  constructor(
    public _translationsService: TranslationsService,
    private _router: Router,
    private metaTitleService: MetaTitleService) {
  }

  ngOnInit() {
    this.metaTitleService.updateTitle('Книги');
    this.metaTitleService.updateTag('description', 'Книги писательницы Иры шиловой.');
  }

  openCategory(location) {
    this._router.navigate([location]);
  }
}

<div class="spinner-container" *ngIf="posts.length === 0">
  <button class="irin-spinner"></button>
</div>

<div class="blog-posts-container">
  <div class="blog-post" *ngFor="let post of posts" (click)="openPost(post.bigId, post.data.title)">
    <div class="post-image">
      <img [src]="post.pictureURL" alt="img">
    </div>
    <div class="post-content">
      <h3 *ngIf="_translationsService.getLocal() === 'ru'">{{post.data.title}}</h3>
      <h3 *ngIf="_translationsService.getLocal() !== 'ru'">{{post.data.titleEng}}</h3>
      <p *ngIf="_translationsService.getLocal() === 'ru'">{{ post.data.description }}</p>
      <p *ngIf="_translationsService.getLocal() !== 'ru'">{{post.data.descriptionEng}}</p>

      <div class="post-footer">
        <p class="date">{{ _translationsService.getLocalResolvedDate(post.data.dateSystem) }}</p>
        <div class="like-section">
          <img src="../../assets/like.png" alt="like">
          <span>{{ post.likes }}</span>
        </div>
      </div>
    </div>
  </div>
</div>

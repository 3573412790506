<header>
  <span id="anch"></span>
  <input type="checkbox" id="nav-toggle" class="nav-toggle">
  <nav class="nav-1">
    <ul>
      <li class="social-menu">
        <a href="https://vk.com/ira.shilova.pisatel" target="_blank" rel="noreferrer"><img
            src="assets/footer-icons/VK.webp" alt="vk" class="social-icon" width="15" title="vk" height="15" /></a>
        <a href="https://www.instagram.com/ira_shilova_pisatel/" target="_blank" rel="noreferrer"><img
            src="assets/footer-icons/instagram.webp" alt="instagram" title="instagram" class="social-icon" width="15"
            height="15" /></a>
        <a href="https://www.facebook.com/irin.untitled/" target="_blank" rel="noreferrer"><img
            src="assets/footer-icons/facebook.webp" alt="facebook" title="facebook" class="social-icon" width="15"
            height="15" /></a>
        <a href="https://t.me/+ladJHNn1irI4NTA0" target="_blank" rel="noreferrer"><img
            src="assets/footer-icons/telegram.png" title="telegram" alt="telegram" class="social-icon" width="15"
            height="15" /></a>
      </li>
      <li> <a routerLink="/about" quicklink>{{_translationsService.getTranslations().menu.about}}</a></li>
      <li><a routerLink="/blog" quicklink>{{_translationsService.getTranslations().menu.blog}}</a></li>
    </ul>
  </nav>
  <h1 class="logo"><img src="assets/pencil-min.webp" alt="header" routerLink="/about" width="200" height="30" /></h1>
  <nav class="nav-2">
    <ul>
      <li> <a routerLink="/book" quicklink>{{_translationsService.getTranslations().menu.book}}</a></li>
      <li> <a routerLink="/illustrations" quicklink>{{_translationsService.getTranslations().menu.illustrations}}</a>
      </li>
    </ul>
  </nav>
  <nav class="nav-3">
    <span class="menu-lang" *ngIf="_translationsService.getLocal() === 'ru'" (click)="changeLang('en')">
      ENG
    </span>
    <span class="menu-lang" *ngIf="_translationsService.getLocal() === 'en'" (click)="changeLang('ru')">
      РУС
    </span>
  </nav>
  <nav>
    <ul>
      <li> <a routerLink="about" (click)="menuClick()"
          quicklink>{{_translationsService.getTranslations().menu.about}}</a>
      </li>
      <li><a routerLink="blog" (click)="menuClick()" quicklink>{{_translationsService.getTranslations().menu.blog}}</a>
      </li>
      <li> <a routerLink="book" (click)="menuClick()" quicklink>{{_translationsService.getTranslations().menu.book}}</a>
      </li>
      <li> <a routerLink="illustrations" (click)="menuClick()"
          quicklink>{{_translationsService.getTranslations().menu.illustrations}}</a></li>
      <li (click)="menuClick()">
        <span class="menu-lang" *ngIf="_translationsService.getLocal() === 'ru'" (click)="changeLang('en')">
          ENG
        </span>
        <span class="menu-lang" *ngIf="_translationsService.getLocal() === 'en'" (click)="changeLang('ru')">
          РУС
        </span>
      </li>
    </ul>
  </nav>
  <label id="nav-toggle-action" for="nav-toggle" class="nav-toggle-label">
    <span></span>
  </label>
</header>


<main>
  <router-outlet (activate)="onActivate($event)"></router-outlet>
</main>

import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root'
})

@Injectable({
  providedIn: 'root'
})
export class MailjetService {

  constructor(private http: HttpClient) { }

  authorize() {
    const headers = new HttpHeaders()
      .set('Authorization', 'Bearer v81XsnYRlHOJ7EyvhDfaeKUH')
      .set('Access-Control-Allow-Origin', '*');

    const body = {
      email: 'pavel.shilov@protonmail.com',
      tokenName: 'ira-shilova'
    };
    return this.http.post("https://api.vercel.com/registration", body, {headers});
  }

  sendEmail(name, lastname, email): Observable<any> {
    const headers = new HttpHeaders()
      .set('Content-Type', 'application/json')
      .set('Access-Control-Allow-Origin', '*');

    return this.http.get('https://ira-shilova-subscription.vercel.app/subscribe?'+'name='+name+'&'+'lastname='+lastname+'&'+'email='+email, {headers});
  }
}

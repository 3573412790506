import { NgModule } from '@angular/core';
import { RouterModule } from '@angular/router';
import { CommonModule } from '@angular/common';
import { AboutComponent } from './about.component';
import { ReactiveFormsModule, FormsModule } from '@angular/forms';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { AngularFirestoreModule } from '@angular/fire/compat/firestore';
import { AngularFireModule } from '@angular/fire/compat';
import { environment } from 'src/environments/environment';
import { QuicklinkStrategy } from 'ngx-quicklink';


@NgModule({
    declarations: [
        AboutComponent,
    ],
    imports: [
        ReactiveFormsModule,
        AngularFirestoreModule,
        AngularFireModule.initializeApp(environment.firebase),
        FormsModule,
        BrowserAnimationsModule,
        CommonModule,
        RouterModule.forChild([{
            path: 'about',
            component: AboutComponent
        }
        ])
    ],
    providers: [],
    bootstrap: [
        AboutComponent
    ],
    exports: [

    ]
})
export class AboutModule {

}

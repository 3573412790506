<div class="spinner-container" *ngIf="illustrations.length === 0">
  <button class="irin-spinner"></button>
</div>

<div class="menu-illustrations">
  <a routerLink="/book/category/howToSaveParents" *ngIf="category === 2"
    class="book-link">{{_translationsService.getTranslations().buttons.book}}</a>
</div>

<div class="images-container" *ngIf="illustrations.length > 0">
  <div class="gallery">
    <img *ngFor="let illustration of illustrations" src="{{ illustration.image?.fields?.file?.url }}" width="250px"
      height="250px" class="image-interactive z-depth-1" (click)="openModal(illustration);" />
  </div>
</div>

<div class="img-wrap hide" id="img-wrap">
  <div class="img-modal-picture">
    <div><img src="{{ currentIllustrationInfo.file }}" class="img-in-modal" id="imgDisplayed" /></div>
    <div class="img-description" *ngIf="_translationsService.getLocal() === 'ru'">
      <h3>
        {{currentIllustrationInfo.description}}
      </h3>
      <h5>
        {{currentIllustrationInfo.tool}}
      </h5>
      <h5>
        {{currentIllustrationInfo.size}}
      </h5>
    </div>
    <div class="img-description" *ngIf="_translationsService.getLocal() !== 'ru'">
      <h3>
        {{currentIllustrationInfo.descriptionEng}}
      </h3>
      <h5>
        {{currentIllustrationInfo.toolEng}}
      </h5>
      <h5>
        {{currentIllustrationInfo.size}}
      </h5>
    </div>
  </div>
  <div class="control">
    <img src="assets/svg/left-arrow.svg" width="30" height="30" (click)="previousIllustration()" *ngIf="isPrevious" />

    <img src="assets/svg/close.svg" width="30" height="30" (click)="closeModal()" />

    <img src="assets/svg/right-arrow.svg" width="30" height="30" (click)="nextIllustration()" *ngIf="isNext" />
  </div>
</div>

import { Component, OnInit } from '@angular/core';
import { TranslationsService } from './translations/translations.service';
import { Router } from '@angular/router';
import { RemoteDataService } from './remote-data.service';
import { MetaTitleService } from './meta-title.service';
import { getAnalytics, logEvent } from 'firebase/analytics';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent implements OnInit {

  public lang: string;

  constructor(
    public _translationsService: TranslationsService,
    private router: Router, private remoteDataService: RemoteDataService,
    private metaTitleService: MetaTitleService) {
  }

  ngOnInit() {
    this.remoteDataService.loadPosts();
    this.remoteDataService.loadIllustrations();

    this.metaTitleService.updateTag('description', 'Сайт автора и иллюстратора детских книг Иры Шиловой.');
    this.metaTitleService.updateTag('title', 'Ира Шилова');
    this.metaTitleService.updateTag('type', 'article');
    this.metaTitleService.updateTag('url', 'ira-shilova.com');
    this.metaTitleService.updateTag('image', '/assets/bio/bio_main_photo.webp');
    this.metaTitleService.updateTag('site_name', 'Ira Shilova');

    const analytics = getAnalytics();
    logEvent(analytics, 'website loaded');
  }

  changeLang(val: string) {
    this._translationsService.setLocal(val);
  }

  menuClick() {
    document.getElementById('nav-toggle-action').click();
  }

  onActivate(event: Event) {
    let el = document.getElementById('anch');
    el.scrollIntoView();
  }

}

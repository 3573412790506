<div class="comment-section">
  <form [formGroup]="commentForm" (ngSubmit)="onSubmit()">
    <div class="comment-label-input textarea">
      <label for="comment">{{_translationsService.getTranslations().about.comment}}</label>
      <textarea id="comment" formControlName="comment" maxlength="1000"></textarea>
      <div
        *ngIf="commentForm.controls['comment'].invalid && (commentForm.controls['comment'].dirty || commentForm.controls['comment'].touched)"
        class="comment-validation">
        <div *ngIf="commentForm.controls['comment'].errors?.required">
          * {{_translationsService.getTranslations().about.requiredComment}}
        </div>
        <input type="text" formControlName="honeypot" style="display:none" />
      </div>
    </div>
    <div class="comment-label-input">
      <label for="name">{{_translationsService.getTranslations().about.name}}</label>
      <input id="name" formControlName="name" />
      <div
        *ngIf="commentForm.controls['name'].invalid && (commentForm.controls['name'].dirty || commentForm.controls['name'].touched)"
        class="comment-validation">
        <div *ngIf="commentForm.controls['name'].errors?.required">
          * {{_translationsService.getTranslations().about.requiredName}}
        </div>
        <div *ngIf="commentForm.controls['name'].errors?.pattern">
          * {{_translationsService.getTranslations().about.wrongName}}
        </div>
      </div>
    </div>
    <div class="comment-label-input">
      <label for="email">{{_translationsService.getTranslations().about.email}}</label>
      <input id="email" formControlName="email" />
      <div
        *ngIf="commentForm.controls['email'].invalid && (commentForm.controls['email'].dirty || commentForm.controls['email'].touched)"
        class="comment-validation">
        <div *ngIf="commentForm.controls['email'].errors?.required">
          * {{_translationsService.getTranslations().about.requiredEmail}}
        </div>
        <div *ngIf="commentForm.controls['email'].errors?.email">
          * {{_translationsService.getTranslations().about.incorrectEmail}}
        </div>
      </div>
    </div>
    <button class="comment-submit" type="submit" [disabled]="commentForm.invalid"
      [@submitAnimation]="submitState">{{_translationsService.getTranslations().about.submit}}</button>
  </form>

  <div *ngIf="submitted" class="submission-notice">
    {{_translationsService.getTranslations().about.commentSubmitted}}
  </div>

  <div class="comments-list">
    <div *ngFor="let comment of comments">
      <div class="comment-single" *ngIf="comment.moderated">
        <p class="comments-name-date">
          <strong><b>{{ comment.name }}</b><span>{{_translationsService.getTranslations().about.commentWriting}}</span></strong>
          <span class="timestamp">{{ _translationsService.getLocalResolvedDate(comment.timestamp) }} :
            {{_translationsService.getLocalResolvedTime(comment.timestamp)}}</span>
        </p>
        <p class="comment-text">{{ comment.comment }}</p>
        <!-- <div *ngIf="!comment.moderated">
          <button (click)="editComment(comment)">Edit</button>
          <button (click)="deleteComment(comment.id)">Delete</button>
        </div> -->
      </div>
    </div>
  </div>
</div>

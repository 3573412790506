import { TranslationsService } from './../../translations/translations.service';
import { Component, OnInit } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { getDownloadURL, getStorage, ref } from '@angular/fire/storage';
import { collection, collectionData, CollectionReference, doc, Firestore, getDoc, query, where } from '@angular/fire/firestore';
import { MetaTitleService } from 'src/app/meta-title.service';
import { UserIdService } from 'src/app/user-id.service';
import { LikeService } from 'src/app/likes.service';
import { Observable } from 'rxjs';

import { trigger, state, style, transition, animate, keyframes } from '@angular/animations';

@Component({
  selector: 'app-blog-page',
  templateUrl: './blog-page.component.html',
  styleUrls: ['./blog-page.component.scss'],
  animations: [
    trigger('likeAnimation', [
      state('liked', style({ transform: 'rotate(0deg) scale(1.2)' })),
      state('notLiked', style({ transform: 'rotate(0deg) scale(1)' })),
      transition('notLiked => liked', [
        animate('0.5s ease-in', keyframes([
          style({ transform: 'rotate(0deg) scale(1)', offset: 0 }),
          style({ transform: 'rotate(20deg) scale(1.5)', offset: 0.5 }),
          style({ transform: 'rotate(0deg) scale(1.2)', offset: 1.0 })
        ]))
      ]),
      transition('liked => notLiked', [
        animate('0.5s ease-out', keyframes([
          style({ transform: 'rotate(0deg) scale(1.2)', offset: 0 }),
          style({ transform: 'rotate(-20deg) scale(1.5)', offset: 0.5 }),
          style({ transform: 'rotate(0deg) scale(1)', offset: 1.0 })
        ]))
      ])
    ])
  ]
})
export class BlogPageComponent implements OnInit {

  post;
  showSpinner = true;

  userId: string;
  likes$: Observable<any>;
  userLiked$: Observable<any>;

  animationState: string = 'notLiked';

  constructor(
    private _router: Router,
    private _activeRouter: ActivatedRoute,
    public _translationsService: TranslationsService,
    private metaTitleService: MetaTitleService,
    private _afs: Firestore,
    private _userIdService: UserIdService,
    private _likesService: LikeService
  ) {
  }

  ngOnInit() {
    this._activeRouter.params.subscribe(params => {
      collectionData<any>(
        query<any, any>(
          collection(this._afs, `posts/`) as CollectionReference<any>,
          where('title', '==', params.title),
        ), { idField: 'id' }).subscribe(posts => {
          this.post = posts[0];
          getDownloadURL(ref(getStorage(), 'images/' + this.post.postId)).then(url => {
            this.post.pictureURL = url;
            let preview = document.getElementById('mainPicture');
            preview.setAttribute('src', url as string);
            this.metaTitleService.updateTag('description', this.post.description);
            this.metaTitleService.updateTitle(this.post.title);

            this.userId = this._userIdService.getUserId();
            this.likes$ = this._likesService.getLikes(this.post.id);
            this.userLiked$ = this._likesService.userLiked(this.post.id, this.userId);

            this.userLiked$.subscribe(userLiked => {
              this.animationState = userLiked ? 'liked' : 'notLiked'; // Set initial animation state based on user liked status
            });

            this.likes$.subscribe(likes => {});
          });
        });
    });
  }

  likePost(): void {
    this._likesService.likePost(this.post.id, this.userId);
    this.animationState = 'liked';
  }

  dislikePost(): void {
    this._likesService.dislikePost(this.post.id, this.userId); // Call the service to dislike the post
    this.animationState = 'notLiked'; // Change the animation state to not liked
  }
}

<section>
  <div class="spinner-container" *ngIf="!post">
    <button class="irin-spinner"></button>
  </div>
  <article>
    <div class="blog-post-container" *ngIf="post">
      <h2 class="blog-post-title" *ngIf="_translationsService.getLocal() === 'ru'">{{ post.title }}</h2>
      <h2 class="blog-post-title" *ngIf="_translationsService.getLocal() !== 'ru'"> {{ post.titleEng }}</h2>
      <img class="blog-img" src="#" id="mainPicture" width="350px" />
      <div *ngIf="_translationsService.getLocal() === 'ru'">
        <div class="post-inner" [innerHTML]="post.content"></div>
      </div>
      <div *ngIf="_translationsService.getLocal() !== 'ru'">
        <div class="post-inner" [innerHTML]="post.contentEng"></div>
      </div>
      <div class="line-container">
        <div class="line"> </div>
        <div class="text">{{_translationsService.getTranslations().blogPage.thanks}}</div>
        <div class="line"></div>
      </div>
      <div class="comment-like-section">
        <div class="like-button">
          <img *ngIf="!(userLiked$ | async)" src="../../../assets/like.png" alt="like" (click)="likePost()" [@likeAnimation]="animationState" />
          <img *ngIf="userLiked$ | async" src="../../../assets/liked.png" alt="dislike" (click)="dislikePost()" [@likeAnimation]="animationState" />
          <p class="like-count">{{ (likes$ | async) }}</p>
        </div>
      </div>
      <div class="comment-form-container">
        <app-comment [postId]="post.id" [postTitle]="post.title"></app-comment>
      </div>
    </div>
  </article>
</section>

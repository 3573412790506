import { trigger, state, style, transition, animate, keyframes } from '@angular/animations';

export const submitAnimation = trigger('submitAnimation', [
  state('default', style({
    transform: 'scale(1)',
    backgroundColor: 'black',
    color: '#fff'
  })),
  state('clicked', style({
    transform: 'scale(.9)',
    backgroundColor: 'white',
    color: '#000'
  })),
  transition('default => clicked', [
    animate('0.3s ease-in', keyframes([
      style({ transform: 'scale(.9)', offset: 0.5 }),
      style({ transform: 'scale(1)', offset: 1 })
    ]))
  ]),
  transition('clicked => default', [
    animate('0.3s ease-out')
  ])
]);

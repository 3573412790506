import { environment } from "./../../environments/environment";
import { Injectable } from "@angular/core";
import * as contentful from "contentful";
import { map } from "rxjs/operators";
import { from } from "rxjs";

@Injectable({
  providedIn: "root"
})
export class ContentfulService {
  private client = contentful.createClient({
    space: environment.contentful.space,
    accessToken: environment.contentful.accessToken
  });

  constructor() { }

  getStories(query?: object) {
    return from(this.client.getEntries({ content_type: 'story', order: '-sys.createdAt' })).pipe(
      map(items => {
        return items;
      })
    );
  }

  getAllIllustrations(category?: number, query?: object) {
    return from(this.client.getEntries({ content_type: "illustration", order: '-sys.createdAt' })).pipe(
      map(items => {
        return items;
      })
    );
  }

  getHistories() {
    return from(this.client.getEntries({ content_type: 'history', order: 'sys.createdAt' })).pipe(
      map(items => {
        return items;
      })
    );
  }

  getOneStory(id) {
    return from(this.client.getEntry(id)).pipe(
      map(entry => {
        return entry.fields;
      })
    );
  }
}

<div class="book-single-container">

  <div class="book-banner" [id]="_translationsService.getLocal() === 'ru' ? 'foxBanner' : 'foxBannerEng'">
  </div>
  <div class="book-about">
    <p>
      {{_translationsService.getTranslations().books.shortDescriptions.fox}}
    </p>
  </div>
  <div *ngIf="_translationsService.getLocal() === 'en'">
    <a class="litres-link" href="https://www.amazon.com/Fox-Skin-English-Ira-Shilova-ebook/dp/B0C6T1PLNB/ref=sr_1_1?__mk_es_MX=%C3%85M%C3%85%C5%BD%C3%95%C3%91&crid=1NUTACCJTIYL1&keywords=ira+shilova&qid=1697684748&sprefix=ira+shi%2Caps%2C196&sr=8-1" target="_blank"
      style="margin-bottom: 25px;">
      <img src="../../../assets/amazon.jpeg" alt="amazon" width="64" height="64" />
      <i class="shop-link">Buy english edition</i>
    </a>
    <a class="litres-link" href="https://www.litres.ru/ira-shilova/lisya-shkurka/" target="_blank">
      <img src="../../../assets/litres.webp" alt="litres" width="64" height="64" />
      <i class="shop-link">Buy russian edition</i>
    </a>
  </div>
  <div *ngIf="_translationsService.getLocal() === 'ru'">
    <a class="litres-link" href="https://www.litres.ru/ira-shilova/lisya-shkurka/" target="_blank"
      style="margin-bottom: 25px;">
      <img src="../../../assets/litres.webp" alt="litres" width="64" height="64" />
      <i class="shop-link">Купить</i>
    </a>
    <a class="litres-link" href="https://www.amazon.com/Fox-Skin-English-Ira-Shilova-ebook/dp/B0C6T1PLNB/ref=sr_1_1?__mk_es_MX=%C3%85M%C3%85%C5%BD%C3%95%C3%91&crid=1NUTACCJTIYL1&keywords=ira+shilova&qid=1697684748&sprefix=ira+shi%2Caps%2C196&sr=8-1" target="_blank">
      <img src="../../../assets/amazon.jpeg" alt="amazon" width="64" height="64" />
      <i class="shop-link">Купить английское издание</i>
    </a>
  </div>

  <div *ngIf="_translationsService.getLocal() === 'ru'">
    <div class="read-header">
      <h2> Лисья шкурка</h2>
      <h3>Девочка Леля</h3>
    </div>
    <div class="read-content">
      <p>Девочка Леля была ни прелестница, ни дурнушка. Ни упрямица, ни безвольница. Она неприметно жила среди трёх
        шумных
        сестёр и трёх крепких братьев, с матерью, но без отца, в овальной избе, посреди леса.
        Леля редко говорила. Пока сёстры разучивали песню, она молча вглядывалась в их пунцовые губы. Когда братья
        убегали
        в чащу ловить зайцев, Леля неподвижно ждала мальчишек возле дома. Ведь наравне с тишиной она ценила сдержанность
        и
        покой. А когда мать укрывалась лисьим тулупом и выгоняла детей из избы, девочка садилась под окнами и
        вслушивалась, осмысляла, предчувствовала. Потому что больше всего Леля дорожила знаниями. И знания эти шептали
        девочке, что семья её люта и изменчива, словно осенняя погода.</p>
    </div>
    <div class="read-container">
      <div class="read-header">
        <h3>Семья Лели</h3>
      </div>
    </div>
    <div class="read-content">
      <p>
        Избу семья Лели не убирала. Печь не топила. Ни метлы, ни лохани, ни ухвата в хозяйстве не держала. Однако жилище
        семейства всегда было опрятно и тепло, а на столе каждый вечер красовались яства из чрева леса, будто невидимые
        слуги пыль выносили, полати стелили, огонь незримый подкармливали, кабанов и тетеревов резали.
      </p>
      <p>Семья Лели к людям не ходила. Но изредка люди приходили к ним. Да вот только и тут Леля видела разброд: гостей
        в
        избу не приглашали, подарки отвергали, с пришедшими разговаривала только мать, да и то едва слышно. Словно не
        проситель к избе наведывался, а опасность подкрадывалась. </p>
      <p>
        Но самым чудным укладом Леле виделся ночной толк. Пока звёзды блуждали по вечности, семейство торопливо
        шепталось
        с явью: мать — с деревьями, братья — с ветром, сёстры — с козодоями, а Леля — с лисицей. И странным это казалось
        девочке и разумным одновременно. Как роскошная позолота на листьях после лета выглядит естественно, так порядок
        семьи для Лели представлялся порядочным. Но ум девочки отличный от ума родни тревожился и ждал отплаты. Ведь
        после
        щедрых осенних урожаев неизменно наступала карающая зима.
      </p>
    </div>
    <div class="read-header">
      <i>Конец ознакомительного отрывка</i>
    </div>
    <a class="litres-link" href="https://www.litres.ru/ira-shilova/lisya-shkurka/" target="_blank"
      style="margin-bottom: 25px;">
      <img src="../../../assets/litres.webp" alt="litres" width="64" height="64">
      <i class="shop-link">Купить</i>
    </a>
    <a class="litres-link" href="https://www.amazon.com/Fox-Skin-English-Ira-Shilova-ebook/dp/B0C6T1PLNB/ref=sr_1_1?__mk_es_MX=%C3%85M%C3%85%C5%BD%C3%95%C3%91&crid=1NUTACCJTIYL1&keywords=ira+shilova&qid=1697684748&sprefix=ira+shi%2Caps%2C196&sr=8-1" target="_blank">
      <img src="../../../assets/amazon.jpeg" alt="amazon" width="64" height="64" />
      <i class="shop-link">Купить английское издание</i>
    </a>
  </div>
  <div *ngIf="_translationsService.getLocal() === 'en'">
    <div class="read-header">
      <h2> The Fox Skin</h2>
      <h3>Lelya girl</h3>
    </div>
    <div class="read-content">
      <p>There was once a girl, named Lelya. Lelya was neither charming nor ugly-looking, neither stubborn nor
        weak-willed. She lived quietly among three clamorous sisters and three strong brothers, with a mother but
        without a dad, in an oval hut in the middle of a forest.</p>
      <p>
        Lelya rarely spoke. While her sisters were learning the Song, she gazed silently at their dark-red lips. When
        her brothers ran into the thicket to catch hares, Lelya waited for the boys near the hut motionless. After all,
        she enjoyed not only silence but also restraint and peace. When the mother put on her a fox coat and drove the
        children out of the hut, the girl sat under the window to listen, to comprehend, to ponder her own thoughts in
        peace; because Lelya treasured wisdom most of all. And this wisdom whispered to the girl that her family was
        fierce and changeable, like the autumn weather.
      </p>
    </div>
    <div class="read-container">
      <div class="read-header">
        <h3>The family of Lelya</h3>
      </div>
    </div>
    <div class="read-content">
      <p>
        Lelya's family did not clean the hut. The family did not heat the stove. The family did not keep a broom, a vat,
        or an oven fork in the household. However, the family's dwelling was always neat and warm, and dishes from the
        womb of the forest appeared on the table every sunset, as if invisible servants carried the dust, laid beds, fed
        the hidden fire, and slaughtered wild boars and grouse.
      </p>
      <p>Lelya's family never went to see people. Yet, once in awhile, when people came to see them, Lelya saw disorder
        in those visits: no one had invited the human guests to the hut, the humans' gifts were rejected, and only the
        mother spoke calmly to those who came, as if they were not the visitors but the danger.</p>
    </div>
    <a class="litres-link" href="https://www.amazon.com/Fox-Skin-English-Ira-Shilova-ebook/dp/B0C6T1PLNB/ref=sr_1_1?__mk_es_MX=%C3%85M%C3%85%C5%BD%C3%95%C3%91&crid=1NUTACCJTIYL1&keywords=ira+shilova&qid=1697684748&sprefix=ira+shi%2Caps%2C196&sr=8-1" target="_blank" style="margin-bottom: 25px;">
      <img src="../../../assets/amazon.jpeg" alt="amazon" width="64" height="64" />
      <i class="shop-link">Buy english edition</i>
    </a>
    <a class="litres-link" href="https://www.litres.ru/ira-shilova/lisya-shkurka/" target="_blank">
      <img src="../../../assets/litres.webp" alt="litres" width="64" height="64">
      <i class="shop-link">Buy russian edition</i>
    </a>
  </div>
</div>

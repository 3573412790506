import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class BlogRedirectService {

  private blogId: string = '';
  private blogTitle: string = '';

  constructor() { }

  getBlogId() {
    if (!this.blogId || this.blogId === '') {
      return this.getIdFromMemory();
    }
    return this.blogId;
  }

  setBlogId(id: string) {
    this.setIdToMemory(id);
    this.blogId = id;
  }

  getBlogTitle() {
    return this.blogTitle;
  }

  setBlogTitle(title: string) {
    this.blogTitle = title;
  }

  getIdFromMemory() {
    return localStorage.getItem('blogIdMemory');
  }
  setIdToMemory(id: string) {
    localStorage.setItem('blogIdMemory', id);
  }
}

<div class="book-single-container">

  <div class="book-banner" [id]="_translationsService.getLocal() === 'ru' ? 'kamillaBanner' : 'kamillaBannerEng'">
  </div>
  <div class="book-about">
    <p>
      {{_translationsService.getTranslations().books.shortDescriptions.kamilla}}
    </p>
  </div>
  <a class="litres-link" href="https://www.litres.ru/ira-shilova/neistovyy-ogon/" target="_blank">
    <img src="../../../assets/litres.webp" alt="litres" width="64" height="64">
    <i class="shop-link" *ngIf="_translationsService.getLocal() === 'ru'">Купить</i>
    <i class="shop-link" *ngIf="_translationsService.getLocal() === 'en'">Russian edition only here</i>
  </a>
  <div *ngIf="_translationsService.getLocal() === 'ru'">
    <div class="read-header">
      <h1>Неистовый огонь</h1>
    </div>
    <div class="read-content">
      <p>
        Мальчишки обморозили пальцы и кончики носов, но продолжали настойчиво копошиться среди облетающих хвойных лап.
        Сдержанное белёсое свечение январского дня готовилось провалиться в студёный мрак и оставить ребят без
        поддержки.
        Как назло, в посёлке кто-то начал жарить картошку, да ещё на сливочном масле с укропом, отчего стылый воздух
        маняще пропитался домашним уютом, подстрекая братьев оставить поиски.
      </p>
      <p>
        — Ну давай, — засопел один из мальчишек. — Мы же в том году целых три игрушки откопали. Чего с этим-то годом не
        так?
        — Фиг знает, — неуклюже пожал плечами второй мальчишка, шурша заношенным пуховиком. — Может, кризис?
        — Какой?
        — Который в стране, — ответил мальчик в пуховике. — Про него родители в новогоднюю ночь говорили.
      </p>
      <p>
        — Это у меня случится кризис, если впервые за... Сколько? Пять лет, ни одной халявной игрушки не притащим.
        Мальчик в пуховике искоса посмотрел на брата, который с головой залез в осыпающиеся ветки, и беспомощно
        вздохнул.
        Мда, пока хоть одна забытая игрушка не найдётся на этом ёлочном кладбище, домой путь заказан. И так всегда. Уж
        если брат поставил себе задачу, то всё сделает для её достижения. Даже нос отморозит. Да чего уж там, даже
        младшему брату нос отморозит, но своего добьётся. Мальчик в пуховике снова вздохнул и приготовился студить
        остальные части тела, потому что верил — «Кризис» заставляет людей внимательнее разбирать новогодние ёлки, а это
        значит, что поиски затянутся до позднего вечера.
      </p>
      <p>
        Но тут случилось волшебство. Раздался победный вскрик:
        — Ага! Нашёл!
        Мальчик в пуховике вздрогнул, будто ему за ворот ледышек накидали, уставился на брата, быстро осмотрел его
        находку
        и улыбнулся. Среди иссушенных елей и закоченевшего полумрака нашлась стеклянная игрушка — сосулька. Хрупкий
        прозрачный конус с шапочкой из серебряных блёсток был спасён.
      </p>
      <p>
        — Супер! — ещё шире улыбнулся посиневшими губами мальчик. — Ты это сделал.
        — Ага, — самодовольно подбросил игрушку на ладони старший брат. — Я. Но чур вешать на ёлку будем вместе, ладно?
        — Ладно.
        — Каждый год.
        — Договорились, — кивнул мальчик в пуховике и дурашливо подмигнул.
      </p>
    </div>
    <div class="read-header">
      <h2>Наши дни</h2>
    </div>
    <div class="read-content">
      <p>Камилле страшно хочется курить. Сначала три часа без табака на рейсе из Москвы до Осло. Потом жалкая вороватая
        затяжка «Честерфилдом» в женском туалете аэропорта «Гардермуэн», которая и за курение-то не считается. Только
        больше никотиновый аппетит разбередила. А после этого ещё 40 минут полёта до Хёгесунда. До города, в котором
        такси
        стоит как недельный набор продуктов московской семьи из двух человек, отчего Камилла испытала острое желание
        сэкономить оставшиеся три сигареты, чтобы не платить ещё и за табак. Который в Норвегии, кстати говоря, стоил
        дороже такси. Что выходило за рамки любой принятой цивилизацией адекватности.</p>
      <p>
        Камилле очень хочется курить, отмокать в горячей ванне и держаться подальше от здешних мест. Но убежать Камилла
        не
        может. Во-первых, она сидит в норвежском кафе, что несколько затрудняет бегство домой в Строгино. Во-вторых, она
        заплатила за две чашки американо и, учитывая местные цены, по моральным причинам не хочет оставлять хоть каплю
        напитка. Надо допивать. И в-третьих, она дала обещание. Себе — что сделает всё — от и до. И ему — что приедет
        сюда
        в канун нового года.
      </p>
      <p>
        Когда Камилла в очередной раз вонзает взгляд в норвежскую парочку, которая окучила столик своими многочисленными
        детьми, и когда Камилла снова задаётся вопросом отчего все эти люди — не блондины, как принято думать, а сплошь
        тёмные кареглазые хлюпики, в кафе заходит он.
        Стягивает с себя вязаную шапку, широким взмахом стряхивает с неё снег, широко улыбается девчонкам за стойкой,
        бодро говорит «хей» и смотрит на Камиллу. Она в ответ даже не моргает. Ждёт, что будет дальше.
      </p>
      <p>
        Он выдыхает, то ли устало, то ли с облегчением, расстёгивает куртку «Nord Face» и уверенно подходит к столику
        Камиллы.
      </p>
      <div>
        — Привет, — первым здоровается он.
        — Привет, — отвечает Камилла, благодаря всех скандинавских
      </div>
      <p>
        богов, что её голос не дрогнул. — Привет, Руслан.
        Он садится за столик, ёрзает на стуле, а затем смело улыбается Камилле:
      </p>
      <p>
        — Ну ты как? Пережила дорогу?
        — Вполне, спасибо, — отчеканивает Камилла. — Почему эта дыра?
        — А это единственное в городе кафе, которое работает по воскресеньям.
        — Да я не про кафе, — Камилла подвигает полную чашку американо к Руслану, будто извиняясь за резкий тон. — Я про
        город. Надеюсь, не из-за меня?
        Мужчина беззлобно усмехается, как усмехается взрослый нелепому вопросу ребёнка, и отвечает:
        — Не из-за тебя. Из-за рыбы. Тут рыбакам поблажки, льготы и уважение. Да и просто... Нравится местная лёгкость
        бытия.
      </p>
      <p>
        — Ну да, — Камилла рефлекторно скрещивает руки, поздно осознав, что зря пытается отгородиться от постных реплик
        мужчины. Уж если начала работать над собой, так давай на полную катушку. Завязывай демонстрировать собеседнику
        отчаянную скуку. Камила торопится исправиться:
        — Вам всем тут нравится?
        — Ага, — добродушно отвечает Руслан и отпивает кофе. — Оля в садик устроилась. Двух зайцев, так сказать: и на
        работу ходит, и Бьёрна из виду не упускает.
      </p>
      <p>
        Камилла, впервые услышав имя племянника, тупо смотрит в пространство за спиной Руслана. Будто хочет
        переместиться
        туда, в безразличную даль. Куда угодно, только бы не сидеть на этом самом месте — на месте испытывающего
        глубокий
        стыд человека.
        — Прости за кафе, — простодушно продолжает мужчина, не замечая остекленелых глаз Камиллы, — боюсь, она пока не
        готова видеть тебя в нашем доме. Ну... После того, что ты сделала...
      </p>
      <p>
        — Ничего, — слишком быстро отвечает женщина, — я понимаю.
        — Поэтому вот, держи, — Руслан протягивает Камилле белую коробочку и листок бумаги. — Спасибо, что решилась. Эта
        штука без тебя ни за что бы не добралась до Москвы.
        Камилла ничего не отвечает. Впервые в жизни она чувствует себя недостойной добра Руслана. Его безоговорочного
        потока тепла и близости. А ведь недавно она по-скотски принимала это благо за само собой разумеющееся.
      </p>
    </div>
    <div class="read-header">
      <i>Конец ознакомительного отрывка</i>
    </div>
    <a class="litres-link" href="https://www.litres.ru/ira-shilova/neistovyy-ogon/" target="_blank">
      <img src="../../../assets/litres.webp" alt="litres" width="64" height="64">
      <i class="shop-link">Купить</i>
    </a>
  </div>
</div>

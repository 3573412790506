import { Component, OnInit } from '@angular/core';
import { TranslationsService } from '../../translations/translations.service'
import { Router } from '@angular/router';
import { MetaTitleService } from 'src/app/meta-title.service';

@Component({
  selector: 'app-book-eternity',
  templateUrl: './book.eternity.component.html',
  styleUrls: ['./book.eternity.component.scss']
})
export class BookEternityComponent implements OnInit {

  constructor(public _translationsService: TranslationsService, private _router: Router, private metaTitleService: MetaTitleService) {
  }

  ngOnInit() {
    this.metaTitleService.updateTitle('Минуя пропасть, направляться в вечность');
    this.metaTitleService.updateTag('description', 'Купить или читать рассказ Минуя пропасть, направляться в вечность автора Иры Шиловой.');
  }
}

import { Injectable } from '@angular/core';
import { Meta } from "@angular/platform-browser";
import { Title } from "@angular/platform-browser";


@Injectable({
  providedIn: 'root'
})
export class MetaTitleService {

  constructor(private meta: Meta, private title: Title) { }

  updateTag(name, content) {
    this.meta.updateTag({
      name: name,
      content: content
    });
  }

  updateTitle(title) {
    this.title.setTitle(title);
  }
}

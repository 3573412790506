<div class="book-single-container">

  <div class="book-banner" [id]="_translationsService.getLocal() === 'ru' ? 'ultramarinBanner' : 'ultramarinBannerEng'">
  </div>
  <div class="book-about">
    <p>
      {{_translationsService.getTranslations().books.shortDescriptions.ultramarin}}
    </p>
  </div>
  <a class="litres-link" href="https://kraftlit.ru/shop/slishkom-mnogo-koshmarov" target="_blank">
    <img src="../../../assets/litres.webp" alt="litres" width="64" height="64">
    <i class="shop-link" *ngIf="_translationsService.getLocal() === 'ru'">Купить</i>
    <i class="shop-link" *ngIf="_translationsService.getLocal() === 'en'">Order a printed hardcover short story collection, russian edition</i>
  </a>
  </div>

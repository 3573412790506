import { Component, OnInit } from '@angular/core';
import { MetaTitleService } from '../meta-title.service';
import { TranslationsService } from '../translations/translations.service';
import { MailjetService } from '../mailjet.service';
import { FormGroup,  FormBuilder,  Validators } from '@angular/forms';
import {buttonAnimation} from './buttonAnimation';
import {inputAnimation} from './inputAnimation'
import { SubscriptionService } from '../subscription.service';
import { AngularFirestore } from '@angular/fire/compat/firestore';

@Component({
  selector: 'app-about',
  templateUrl: './about.component.html',
  styleUrls: ['./about.component.scss'],
  animations: [buttonAnimation, inputAnimation]
})
export class AboutComponent implements OnInit {

  public subscriptionForm: FormGroup;
  buttonState = 'default';
  inputState = 'default';
  isSubscribed = false;

  constructor(public _translationsService: TranslationsService, private metaTitleService: MetaTitleService,
      private mailjetService: MailjetService, private _fb: FormBuilder, public _subscriptionService: SubscriptionService,
      private _firestore: AngularFirestore
  ) {
      this.createSubscriptionForm();
   }

  ngOnInit() {
    this.metaTitleService.updateTag('description', 'Сайт автора и иллюстратора детских книг Иры Шиловой.');
    this.metaTitleService.updateTitle('Ира Шилова');
  }

  createSubscriptionForm() {
    this.subscriptionForm = this._fb.group({
      name: ['', Validators.required],
      lastname: ['', Validators.required ],
      email: ['', [Validators.required, Validators.email] ]
   });
  }

  subscribe() {
    const name = this.subscriptionForm.controls['name'].value;
    const lastname = this.subscriptionForm.controls['lastname'].value;
    const email = this.subscriptionForm.controls['email'].value;

    this.buttonState = this.buttonState === 'default' ? 'subscribed' : 'default';
    this.inputState = this.inputState === 'default' ? 'subscribed' : 'default';
    this.isSubscribed = true;
    //if (this._subscriptionService.getIsSubscribed()) {return;}
    this.checkIfSubscriptionExists(email).subscribe(
      querySnapshot => {
        if (querySnapshot.empty) {
          this._firestore.collection('subscriptions').add(
            {
              email: email,
              name: name,
              lastname: lastname
            }
          ).then(resp => {
            this.mailjetService.sendEmail(name, lastname, email)
              .subscribe(
                {
                  next: (v) => {},
                  error: (e) => {},
                  complete: () => {}
                }
              );
          this._subscriptionService.setIsSubscribed(true);
        }).catch(err => {});
      }
    }
  );
}

  checkIfSubscriptionExists(email: string) {
    return this._firestore.collection('subscriptions', ref => ref.where('email', '==', email)).get();
  }
}

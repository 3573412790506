export const ru = {
  menu: {
    about: 'Обо мне',
    book: 'Книги',
    illustrations: 'Иллюстрации',
    blog: 'Блог'
  },
  buttons: {
    back: 'Назад',
    book: 'Книга'
  },
  footer: {
    copyright: '© 2020 копирайт'
  },
  about: {
    irina: 'Ирина Шилова',
    textName: 'Меня зовут Ира. Я пишу книги для детей и подростков, рисую книжные иллюстрации и пишу рассказы для взрослых людей.',
    textHello: 'Привет!',
    textTravel: 'Я родилась в Великом Новгороде, а детство провела во Владимирской области. Ребёнком любила смотреть мультики, рисовать животных, писать истории шариковой ручкой на большом листе бумаги и гулять в лесу. C тех пор во мне почти ничего не изменилось. Разве что добавилась любовь к долгим приключениям: я уже много лет живу то в одной стране, то в другой. Сейчас мой муж и я живём во Франции, а до этого мы изучали Чехию, Эстонию, Норвегию и Мексику. Жизнь, полная перемен, отлично подсвечивает, какой ты человек и куда тебе нужно расти. Путешествия учат меня, что каждый день нужно становиться сильнее, добрее, внимательнее. Не удивительно, что идея саморазвития так часто встречается в моих историях.',
    textEducation: 'Кстати, серьёзно и трепетно заниматься писательством я начала после того, как помогла свершиться множеству чужих путешествий. Дело в том, что я закончила Российскую международную академию туризма и несколько лет работала в туриндустрии, организовывая самые настоящие приключения. А однажды мне захотелось устроить приключение для себя — осуществить мечту детства и стать хорошей писательницей и умелым иллюстратором.',
    textFirstBook: 'Моя первая книга — "Как спасти родителей", иллюстрированная история для ребят, которые только-только пошли в школу. Я люблю писать детскую и подростковую литературу, потому что, верю — хорошая книга, прочитанная в юном возрасте, подготавливает к счастливой, яркой взрослой жизни. А хорошая книга с картинками подготавливает ещё лучше.',
    textFirstBook2: 'Но иногда мне кажется, что в мире очень много людей, у которых в детстве не было хороших книг, и тогда я пишу истории для взрослых. Чтобы поддержать и утешить, или попугать и заставить задуматься.',
    textEnding: 'Сегодня я издаюсь на ЛитРес и в инди-издательствах. А на этом сайте собираю все свои истории, рисунки и заметки, поскольку хочу показать, что путешествие у писателя непростое и долгое. А если тебе интересно наблюдать за развитием моего творчества, подпишись на обновления. Мне будет приятно путешествовать в компании.',
    wantSubscribe: 'Подписаться',
    name: 'Имя',
    lasname: 'Фамилия',
    email: 'Почта',
    requiredName: 'Введите имя',
    wrongName: 'Имя может содержать только буквы и пробелы',
    commentSubmitted: 'комментарий будет опубликован после модерации',
    requiredLastname: 'Введите фамилию',
    requiredEmail: 'Введите почту',
    incorrectEmail: 'Проверьте почту',
    subscribe: 'Подписаться!',
    placeholderName: 'Твое имя',
    placeholderLastname: 'Твоя фамилия',
    placeholderEmail: 'твой@email.com',
    thanks: 'Спасибо!',
    requiredComment: 'А как же комментарий? :)',
    comment: 'Оставить комментарий',
    commentWriting: 'пишет:',
    submit: 'Отправить'
  },
  blogPage: {
    thanks: 'Спасибо, что читаете'
  },
  imgModal: {
    description: 'Описание: ',
    tool: 'Инструменты:',
    originalSize: 'Оригинальный размер: '
  },
  signature: 'Ирина Шилова',
  books: {
    buttons: {
      read: 'Читать',
      order: 'Заказать',
      buy: 'Купить',
      play: 'Запустить',
      workInProgress: 'Над этой книгой я работаю прямо сейчас.'
    },
    titles: {
      stories: {
        viktor: 'Истории Виктора',
        janMark: 'Истории про Яна и Марка Горечавка',
      },
      trailers: {
        howToSaveParents: 'Буктрейлер книги "Как спасти родителей"'
      },
      howToSaveParents: 'Как спасти Родителей',
      howToFixEmptiness: 'Как исправить Пустоту',
      end: 'Конец',
      fox: 'Лисья шкурка',
      kamilla: 'Неистовый огонь (18+)',
      ultramarin: 'Ультрамарин (18+)',
      tryapka: 'Тряпка (18+)',
      neesh: 'Не ешь меня',
      eternity: 'Минуя пропасть, направляться в вечность',
      book: 'Книга',
      tale: 'Рассказ'
    },
    shortDescriptions: {
      viktorStories: 'Здесь ты можешь взглянуть на главного героя книги Виктора ещё до того, ' +
        'как он отправился в приключения, чтобы спасти родителей.',
      janMarkStories: 'Взгляни-ка на двух близнецов Яна и Марка Горчечавка, пока они не ушли в поход на поиски Жар-Птицы.',
      trailers: {
        howToSaveParents: 'Давай познакомимся с персонажами книги и узнаем, какие приключения их ждут.'
      },
      howToSaveParents: 'Книга расскажет о том, как простой мальчик из семьи, в которой привыкли к рутине, ' +
        'должен спасти своих родителей от мести таинственного Чужака, но единственное умение ребёнка - это выгуливание пёсика.',
      howToFixEmptiness: 'Неразлучные братья близнецы отправляются на поиски Жар-Птицы, но в этом приключении они могут рассчитывать только на помощь причудливых говорящих животных, которые просят ребят разгадать тайну исчезнувших припасов.',
      fox: 'Девочка Леля, непохожая на свою родню, узнаёт мрачный семейный секрет, из-за которого начинает путешествие прочь от дома и от себя самой.',
      kamilla: 'У Камиллы есть две особенности, которые определяют её жизнь: первая — темперамент, который сложно не заметить, вторая — история из прошлого, которую необходимо исправить ради примирения с собой и близкими.',
      ultramarin: 'Эта короткая история на страницу, вошедшая в печатный сборник рассказов — исповедь влюблённого мужчины, который год за годом всё лучше узнаёт свою жену, но то, что ему открывается, внушает отчаянный страх и отвращение.',
      tryapka: 'Затравленная робкая женщина, преследуемая незнакомцем, тщательно исследует воспоминания, чтобы найти в них подсказку, как избавиться от докучающей тени и достойно защитить смысл своей жизни.',
      neesh: 'Сказка про Красную Шапочку, за которой охотится страшный Волк. Однако Волк живёт не в лесу, а прямо в головке Красной Шапочки',
      eternity: 'Однажды человеческий мальчик подружился с троллем-рыбаком, и вместе они учились понимать реку, мир и саму жизнь. Но с такой же силой, с какой дружба открывала мальчику и троллю новые знания, старые привычки и непохожий нрав жестоко испытывали друзей.',
    }
  },
  illustrations: {
    categories: {
      howToSaveParents: 'Как Спасти Родителей',
      howToFixEmptiness: 'Как Исправить Пустоту',
      pictures: 'Картинки'
    },
    tooltips: {
      openOrigin: 'Открыть оригинал'
    }
  }
};

<div class="book-single-container">

  <div class="book-banner" [id]="_translationsService.getLocal() === 'ru' ? 'tryapkaBanner' : 'tryapkaBannerEng'">
  </div>
  <div class="book-about">
    <p>
      {{_translationsService.getTranslations().books.shortDescriptions.tryapka}}
    </p>
  </div>
  <a class="litres-link" href="https://www.litres.ru/ira-shilova/tryapka/" target="_blank">
    <img src="../../../assets/litres.webp" alt="litres" width="64" height="64">
    <i class="shop-link" *ngIf="_translationsService.getLocal() === 'ru'">Купить</i>
    <i class="shop-link" *ngIf="_translationsService.getLocal() === 'en'">Russian edition only here</i>
  </a>
  <div *ngIf="_translationsService.getLocal() === 'ru'">
    <div class="read-header">
      <h2>
        Сейчас
      </h2>
    </div>
    <div class="read-content">
      <p>
        Конечно, мне не нравится работать семь дней в неделю по шестнадцать часов в сутки. Днём — портнихой, штампуя
        семейные трусы и простыни, вечером — уборщицей ресторана “Менуэт”, оттирая майонез и макаронную подливку с пола.
        Тяжёлая и неблагодарная работа. За такую никому не хочется браться. Но мне она необходима. Я отложила на будущее
        скромные удовольствия и попытки отдохнуть душой, поскольку сейчас главное — работать. И не забывать зачем.

        Мне также полагается следить за выражением лица — в меру улыбчивое, в меру отрешённое. Ровно такое, какое
        стерпит
        обозлённый человек. Нельзя мешаться на тротуарах, беспредметно говорить, чихать. Только с подобным поведением
        местные мирятся и позволяют нам дожидаться Его выхода. Остался год. Потом мы уедем из этого хмурого городка и
        начнём новую жизнь: без портного цеха, без халата уборщицы, без осуждающих взглядов.

        Я уверена, что у меня всё получится. Что я дождусь Его. Обниму Его. Покажу Ему наше Чудо. Но неясный силуэт,
        который уже несколько раз мелькал на заброшенной бензоколонке напротив “Менуэта”, зачал в уме мрачное опасение —
        а
        что если кто-то готовится мне помешать? Следит, выискивает изъяны, оценивает моё право на безмятежное счастье.

        Но разве плохо то, что молодая женщина смиренно дожидается любимого, напитывая дни усердной работой? А может, я
        прошлыми делами перечеркнула все шансы на будущее блаженство? Чушь. За моими плечами много горя, суеты,
        разочарования, но нет ничего, что опозорило бы меня, Его, или наше Чудо.
      </p>
    </div>
    <div class="read-header">
      <h2>
        Тогда
      </h2>
    </div>
    <div class="read-content">
      <p>
        Да, я родила дочь в пятнадцать лет. Нынешнее общество говорит, что это ненормально. Для них это повод обзывать
        меня потаскухой, тупорылой, швалью. Сначала они видели во мне только ребёнка, который из любознательности,
        неразумности, покорности испортил жизнь себе и невинному младенцу. Сейчас они воспринимают меня, как
        человека-тряпку, сожалеющего о прошлом и равнодушно доживающего отведённое время.

        Как это близоруко. Всего какой-то век назад меня поздравили бы с первым приплодом и напутствовали бы на
        следующее
        вынашивание детей. Но общественные рамки сжались, как сжимаются перегородки загона возле убойного цеха, и девять
        лет назад никто не рассмотрел в нашем с Ним союзе подлинной любви, никто не расслышал моих жалобных криков,
        когда
        испуганно вопящую дочь уносили прочь от моей едва разродившейся утробы, никто не заметил, что принёс в жертву
        образцовому порядку честных и безвинных людей.

        Но что было, то было. Со временем забывается глубина причинённой боли, гнев сменяется застарелой обидой, и жизнь
        превращается в опасливое странствие от одного дня к другому. Общество выполнило свой долг, я смирилась с этим.
        Оно
        забрало мою девочку в дом малютки, а меня отправили в интернат для подростков. Мы принудительно окунулись в
        очистительные ритуалы приютов, смывая с прошлого мнимые грехи, и сейчас тоскливыми ночами мне дарит покой то
        знание, что из нас двоих детдомовские правила помню только я.
      </p>
    </div>
    <a class="litres-link" href="https://www.litres.ru/ira-shilova/tryapka/" target="_blank">
      <img src="../../../assets/litres.webp" alt="litres" width="64" height="64">
      <i class="shop-link">Купить</i>
    </a>
  </div>
</div>

import { Component, OnInit } from '@angular/core';
import { TranslationsService } from '../../../../translations/translations.service';
import { ActivatedRoute, Router } from '@angular/router';
import { ContentfulService } from 'src/app/blog/contentful.service';
import { documentToHtmlString } from '@contentful/rich-text-html-renderer';
import { MetaTitleService } from 'src/app/meta-title.service';

@Component({
  selector: 'app-story-jan-mark',
  templateUrl: './jan-mark-story.component.html',
  styleUrls: ['./jan-mark-story.component.scss']
})
export class StoryJanMarkComponent implements OnInit {

  storyId: number;
  story;
  showSpinner = true;
  richText;

  constructor(public _translationsService: TranslationsService, private _router: Router, private _activeRouter: ActivatedRoute, private _service: ContentfulService, private metaTitleService: MetaTitleService) {
  }


  ngOnInit() {
    this._activeRouter.params.subscribe(params => {
      this.storyId = params.id;
    });

    this._activeRouter.params.subscribe(params => {
      this._service.getOneStory(params.id).subscribe(fields => {
        this.story = fields;

        this.showSpinner = false;

        const options = {
          renderNode: {
            'embedded-asset-block': (node) =>
              `<img class="img-fluid" src="${node.data.target.fields.file.url}"/>`
          }
        };

        this.metaTitleService.updateTitle(this.story.name);
        this.metaTitleService.updateTag('description', 'Истории про Яна и Марка Горечавка. Персонажи книги Как исправить пустоту. Автор Ира Шилова.');

        this.richText = documentToHtmlString(this.story.storyText, options);
      });
    });
  }

  goBack() {
    this._router.navigate(['book/category/howToFixEmptiness'], { skipLocationChange: true });
  }
}

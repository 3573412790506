import { Component, OnInit } from '@angular/core';
import { TranslationsService } from '../../translations/translations.service'
import { MetaTitleService } from 'src/app/meta-title.service';

@Component({
  selector: 'app-book-fox',
  templateUrl: './book.fox.component.html',
  styleUrls: ['./book.fox.component.scss']
})
export class BookFoxComponent implements OnInit {
  constructor(public _translationsService: TranslationsService,  private metaTitleService: MetaTitleService) {
  }

  ngOnInit() {
    this.metaTitleService.updateTitle('Лисья шкурка');
    this.metaTitleService.updateTag('description', 'Купить или читать рассказ Лисья Шкурка автора Иры Шиловой.');
  }
}

import { Component, OnInit } from '@angular/core';
import { TranslationsService } from '../translations/translations.service';
import { NavigationEnd, Router } from '@angular/router';

import { MetaTitleService } from '../meta-title.service';
import { RemoteDataService } from '../remote-data.service';
import { BlogRedirectService } from '../blog-redirect.service';
import { filter } from 'rxjs';

@Component({
  selector: 'app-blog',
  templateUrl: './blog.component.html',
  styleUrls: ['./blog.component.scss']
})
export class BlogComponent implements OnInit {
  showSpinner: boolean = true;
  posts = [];

  constructor(public _translationsService: TranslationsService, private _router: Router,
    private metaTitleService: MetaTitleService, private remoteDataService: RemoteDataService,
    private blogRedirectService: BlogRedirectService) { }

  ngOnInit() {
    this.getPosts();
    this.metaTitleService.updateTitle('Блог');
    this.metaTitleService.updateTag('description', 'Блог писателя Иры Шиловой');
  }

  openPost(id, title) {
    this.blogRedirectService.setBlogId(id);
    this.blogRedirectService.setBlogTitle(title);

    this._router.navigate(['blog/', title]);
  }

  getPosts() {
    this.posts = this.remoteDataService.getPosts();
  }

}

import { Component, OnInit } from '@angular/core';
import { TranslationsService } from '../../translations/translations.service'
import { Router } from '@angular/router';
import { MetaTitleService } from 'src/app/meta-title.service';

@Component({
  selector: 'app-book-kamilla',
  templateUrl: './book.kamilla.component.html',
  styleUrls: ['./book.kamilla.component.scss']
})
export class BookKamillaComponent implements OnInit {

  constructor(public _translationsService: TranslationsService, private _router: Router, private metaTitleService: MetaTitleService) {
  }

  ngOnInit() {
    this.metaTitleService.updateTitle('Неистовый огонь');
    this.metaTitleService.updateTag('description', 'Купить или читать рассказ Неистовый огонь автора Иры Шиловой.');
  }
}

import { Injectable } from '@angular/core';
import { ru } from './ru';
import { en } from './en';

@Injectable({
  providedIn: 'root'
})
export class TranslationsService {

  private local = null;

  constructor() {
    if (localStorage.getItem('authenticated') == undefined) {
      this.local = 'ru';
      localStorage.setItem('authenticated', JSON.stringify({ lang: this.local }));
    } else {
      this.local = JSON.parse(localStorage.getItem('authenticated')).lang;
    }
  }

  public getLocal() {
    if (JSON.parse(localStorage.getItem('authenticated')).lang === undefined) {
      this.local = 'ru';
      return this.local;
    }
    this.local = JSON.parse(localStorage.getItem('authenticated')).lang;
    return this.local;
  }

  setLocal(local: string) {
    this.local = local;
    localStorage.setItem('authenticated', JSON.stringify({ lang: this.local }));
  }

  getTranslations() {
    switch (this.local) {
      case 'ru': return ru;
      case 'en': return en;
      default: return ru;
    }
  }

  getLocalResolvedTime(date) {
    const originDate = new Date(date);
    const hours = String(originDate.getUTCHours()).padStart(2, '0');
    const minutes = String(originDate.getUTCMinutes()).padStart(2, '0');
    return `${hours}:${minutes}`;
  }

  getLocalResolvedDate(date) {
    let originDate = new Date(date);
    let result = '' + originDate.getUTCDate();

    switch (originDate.getMonth() + 1) {
      case 1:
        this.getLocal() === 'ru' ? result += ' Января' : result += ' January'
        break;
      case 2:
        this.getLocal() === 'ru' ? result += ' Февраля' : result += ' February'
        break;
      case 3:
        this.getLocal() === 'ru' ? result += ' Марта' : result += ' March'
        break;
      case 4:
        this.getLocal() === 'ru' ? result += ' Апреля' : result += ' April'
        break;
      case 5:
        this.getLocal() === 'ru' ? result += ' Мая' : result += ' May'
        break;
      case 6:
        this.getLocal() === 'ru' ? result += ' Июня' : result += ' June'
        break;
      case 7:
        this.getLocal() === 'ru' ? result += ' Июля' : result += ' July'
        break;
      case 8:
        this.getLocal() === 'ru' ? result += ' Августа' : result += ' August'
        break;
      case 9:
        this.getLocal() === 'ru' ? result += ' Сентября' : result += ' September'
        break;
      case 10:
        this.getLocal() === 'ru' ? result += ' Октября' : result += ' October'
        break;
      case 11:
        this.getLocal() === 'ru' ? result += ' Ноября' : result += ' Novebmer'
        break;
      case 12:
        this.getLocal() === 'ru' ? result += ' Декабря' : result += ' December'
        break;
    }

    result += ' , ' + originDate.getUTCFullYear();

    return result;
  }
}

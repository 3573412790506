<div class="story-container" *ngIf="story">
    <div class="story-title">
        <h1 class="h1-responsive">{{story.name}}</h1>
    </div>
    <div class="story-image">
        <img src="{{story.imageFull.fields.file.url}}" alt="Jan & Mark">
    </div>
    <div class="story-text">
        <div class="story-inner-text" [innerHTML]="richText"></div>
    </div>
    <div class="story-end">
        <h1 class="h1-responsive">{{_translationsService.getTranslations().books.titles.end}}</h1>
    </div>
</div>

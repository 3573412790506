<div class="book-single-container">
  <div class="book-banner" [id]="_translationsService.getLocal() === 'ru' ? 'neeshBanner' : 'neeshBannerEng'">
  </div>
  <div class="book-about">
    <p>
      {{_translationsService.getTranslations().books.shortDescriptions.neesh}}
    </p>
  </div>
  <a class="litres-link" href="https://www.litres.ru/ira-shilova/ne-esh-menya/" target="_blank">
    <img src="../../../assets/litres.webp" alt="litres" width="64" height="64">
    <i class="shop-link" *ngIf="_translationsService.getLocal() === 'ru'">Читать бесплатно</i>
    <i class="shop-link" *ngIf="_translationsService.getLocal() === 'en'">Russian edition only here</i>
  </a>
</div>

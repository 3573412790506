<div class="i-categories-container">
    <div class="img-category" routerLink="/illustrations/save-parents">
        <img src="../../assets/covers/cover_how_to_save_parents.webp" height="250px" alt="category" />
        <h4 class="pt-3 px-2 pb-2">{{_translationsService.getTranslations().illustrations.categories.howToSaveParents}}</h4>
    </div>
    <div class="img-category" routerLink="/illustrations/histories">
        <img src="../../assets/covers/other.webp" height="250px" alt="category">
        <h4 class="pt-3 px-2 pb-2">{{_translationsService.getTranslations().illustrations.categories.pictures}}</h4>
    </div>
    <div class="img-category" routerLink="/illustrations/emptiness">
        <img src="../../assets/covers/cover_how_to_fix_emptiness_i.webp" height="250px" alt="category">
        <h4 class="pt-3 px-2 pb-2">{{_translationsService.getTranslations().illustrations.categories.howToFixEmptiness}}</h4>
    </div>
</div>
import { Injectable } from '@angular/core';
import { AngularFirestore } from '@angular/fire/compat/firestore';
import { Observable, map } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class LikeService {
  constructor(private _firestore: AngularFirestore) {}

  getLikes(postId: string): Observable<any> {
    return this._firestore
      .collection('posts')
      .doc(postId)
      .collection('likes')
      .valueChanges()
      .pipe(
        map(likes => likes.length)
      );
  }

  likePost(postId: string, userId: string): void {
    this._firestore
      .collection('posts')
      .doc(postId)
      .collection('likes')
      .doc(userId)
      .set({ liked: true });
  }

  dislikePost(postId: string, userId: string): void {
    this._firestore
      .collection('posts')
      .doc(postId)
      .collection('likes')
      .doc(userId)
      .delete();
  }

  userLiked(postId: string, userId: string): Observable<any> {
    return this._firestore
      .collection('posts')
      .doc(postId)
      .collection('likes')
      .doc(userId)
      .valueChanges();
  }
}

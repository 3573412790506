<div class="books-top-container">
  <div class="books-container">
    <article class="book-item category" (click)="openCategory('book/category/howToSaveParents')">
      <div class="book-item-header">
        <h3>{{_translationsService.getTranslations().books.titles.howToSaveParents}}</h3>
      </div>
      <div class="book-image">
        <video width="300" muted autoplay loop playsinline src="../../assets/book1.webm"  type="video/webm" id="book1">

        </video>
      </div>
      <div class="book-bottom">
        <p *ngIf="_translationsService.getLocal() === 'ru'">Книга</p>
        <p *ngIf="_translationsService.getLocal() === 'en'">story, reading age 6+</p>
      </div>
    </article>
    <article class="book-item category" (click)="openCategory('book/category/howToFixEmptiness')">
      <div class="book-item-header">
        <h3>{{_translationsService.getTranslations().books.titles.howToFixEmptiness}}</h3>
      </div>
      <div class="book-image">
        <video width="300" muted autoplay loop playsinline src="../../assets/book2.webm" type="video/webm" id="book2">

        </video>
      </div>
      <div class="book-bottom">
        <p *ngIf="_translationsService.getLocal() === 'ru'">Книга</p>
        <p *ngIf="_translationsService.getLocal() === 'en'">novella, reading age 6+</p>
      </div>
    </article>
    <article class="book-item category" (click)="openCategory('book/category/lisya-shkurka')">
      <div class="book-item-header">
        <h3>{{_translationsService.getTranslations().books.titles.fox}}</h3>
      </div>
      <div class="book-image">
        <video width="300" muted autoplay loop playsinline src="../../assets/bookFox.webm" type="video/webm" id="book3">

        </video>
      </div>
      <div class="book-bottom">
        <p *ngIf="_translationsService.getLocal() === 'ru'">Рассказ</p>
        <p *ngIf="_translationsService.getLocal() === 'en'">short novella, reading age 6+</p>
      </div>
    </article>
    <article class="book-item category" (click)="openCategory('book/category/neistovy-ogon')">
      <div class="book-item-header">
        <h3>{{_translationsService.getTranslations().books.titles.kamilla}}</h3>
      </div>
      <div class="book-image">
        <video width="300" muted autoplay loop playsinline src="../../assets/bookKamilla.webm" type="video/webm" id="book4">

        </video>
      </div>
      <div class="book-bottom">
        <p *ngIf="_translationsService.getLocal() === 'ru'">Рассказ</p>
        <p *ngIf="_translationsService.getLocal() === 'en'">short novella, reading age 18+</p>
      </div>
    </article>
    <article class="book-item category" (click)="openCategory('book/category/ultramarin')">
      <div class="book-item-header">
        <h3>{{_translationsService.getTranslations().books.titles.ultramarin}}</h3>
      </div>
      <div class="book-image">
        <video width="300" muted autoplay loop playsinline src="../../assets/book-hats/ultramarin.webm"
          type="video/webm" id="book5">

        </video>
      </div>
      <div class="book-bottom">
        <p *ngIf="_translationsService.getLocal() === 'ru'">Рассказ</p>
        <p *ngIf="_translationsService.getLocal() === 'en'">literary miniature, reading age 18+</p>
      </div>
    </article>
    <article class="book-item category" (click)="openCategory('book/category/tryapka')">
      <div class="book-item-header">
        <h3>{{_translationsService.getTranslations().books.titles.tryapka}}</h3>
      </div>
      <div class="book-image">
        <video width="300" muted autoplay loop playsinline src="../../assets/book-hats/tryapka.webm" type="video/webm" id="book6">

        </video>
      </div>
      <div class="book-bottom">
        <p *ngIf="_translationsService.getLocal() === 'ru'">Рассказ</p>
        <p *ngIf="_translationsService.getLocal() === 'en'">short novella, reading age 18+</p>
      </div>
    </article>
    <article class="book-item category" (click)="openCategory('book/category/ne-esh-menya')">
      <div class="book-item-header">
        <h3>{{_translationsService.getTranslations().books.titles.neesh}}</h3>
      </div>
      <div class="book-image">
        <video width="300" muted autoplay loop playsinline src="../../assets/book-hats/neesh.webm" type="video/webm" id="book7">

        </video>
      </div>
      <div class="book-bottom">
        <p *ngIf="_translationsService.getLocal() === 'ru'">Рассказ</p>
        <p *ngIf="_translationsService.getLocal() === 'en'">tale-parable, reading age 6+</p>
      </div>
    </article>
    <article class="book-item category" (click)="openCategory('book/category/eternity')">
      <div class="book-item-header">
        <h3>{{_translationsService.getTranslations().books.titles.eternity}}</h3>
      </div>
      <div class="book-image">
        <video width="300" muted autoplay loop playsinline src="../../assets/06.webm" type="video/webm" id="book8">

        </video>
      </div>
      <div class="book-bottom">
        <p *ngIf="_translationsService.getLocal() === 'ru'">Рассказ</p>
        <p *ngIf="_translationsService.getLocal() === 'en'">tale-parable, reading age 6+</p>
      </div>
    </article>
  </div>
</div>

import { Component, OnInit } from '@angular/core';
import { TranslationsService } from '../../translations/translations.service'
import { Router } from '@angular/router';
import { MetaTitleService } from 'src/app/meta-title.service';

@Component({
  selector: 'app-book',
  templateUrl: './book.howToSaveParents.component.html',
  styleUrls: ['./book.howToSaveParents.component.scss']
})
export class BookHowToSaveParentsComponent implements OnInit {

  isBuyOptionsShowing = false;

  constructor(public _translationsService: TranslationsService, private _router: Router, private metaTitleService: MetaTitleService) {
  }

  ngOnInit() {
    this.metaTitleService.updateTitle('Как спасти родителей');
    this.metaTitleService.updateTag('description', 'Купить или читать книгу Как спасти родителей автора Иры Шиловой.');

  //  document.getElementById('mask').addEventListener('click', () => {
  //    this.closeVideo();
  //  });
  }

  showBuyOptions() {
    this.isBuyOptionsShowing = !this.isBuyOptionsShowing;
  }

  openViktorHistories() {
    this._router.navigate(['history/viktor'], { skipLocationChange: true });
  }

  redirectToHowToSaveParents() {
    this._router.navigate(['book/read/how-to-save-parents'], { skipLocationChange: true });
  }

  //runVideo() {
  //  document.getElementById('mask').classList.remove('trailer-hidden');
  //  document.getElementById('mask').classList.add('trailer-play');
  //  document.querySelector('iframe').src = "https://www.youtube.com/embed/1sKzVc5t9WQ";
 // }
 // closeVideo() {
 //   document.getElementById('mask').classList.remove('trailer-play');
 //   document.getElementById('mask').classList.add('trailer-hidden');
 //   document.querySelector('iframe').src = "";
 // }
}

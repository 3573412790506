<!-- Paralax -->
<div class="back-top" id="top"
  [ngClass]="{'eng': _translationsService.getLocal() !== 'ru', 'ru': _translationsService.getLocal() === 'ru'}"></div>
<div class="back-top-mobile"
  [ngClass]="{'eng': _translationsService.getLocal() !== 'ru', 'ru': _translationsService.getLocal() === 'ru'}"></div>
<!-- Paralax end -->

<div class="back-top-mobile-image">
  <img class="rounded-circle" width="250px" height="250px" src="assets/bio/bio_main_photo.webp" alt="Irina Shilova"
    id="aboutImg" />
</div>

<main style="padding-bottom: 50px;">
  <div class="about-chapter">
    <p class="chapter-caption">
      {{_translationsService.getTranslations().about.textHello}}
    </p>
    <p class="chapter-chapter-sub">
      {{_translationsService.getTranslations().about.textName}}
    </p>
  </div>
  <article class="me-cards">
    <div class="me-card">
      <img src="assets/bio/bio_kid.webp" alt="" class="me-card-img">
      <div class="me-card-text">
        {{_translationsService.getTranslations().about.textTravel}}
      </div>
    </div>
    <div class="me-card">
      <div class="me-card-text">
        {{_translationsService.getTranslations().about.textEducation}}
      </div>
      <img src="assets/bio/bio_hat.webp" alt="" class="me-card-img">
    </div>
    <div class="me-card">
      <img src="assets/bio/bio_book.webp" alt="" class="me-card-img">
      <div class="me-card-text">
        {{_translationsService.getTranslations().about.textFirstBook}}
        <br /> <br />
        {{_translationsService.getTranslations().about.textFirstBook2}}
      </div>
    </div>
    <div class="me-card">
      <div class="me-card-text">
        {{_translationsService.getTranslations().about.textEnding}}
      </div>
      <img src="assets/bio/bio_sign.webp" alt="" class="me-card-img">
    </div>
  </article>

  <form class="me-subscribe" [formGroup]="subscriptionForm" novalidate id="subscriptionFormAbout">
    <div class="me-subscribe-field" [@inputState]="inputState">
      <label for="name">{{_translationsService.getTranslations().about.name}}</label>
      <input type="text" formControlName="name" />
      <div
        *ngIf="subscriptionForm.controls['name'].invalid && (subscriptionForm.controls['name'].dirty || subscriptionForm.controls['name'].touched)"
        class="subscribe-validation">
        <div *ngIf="subscriptionForm.controls['name'].errors?.required">
          * {{_translationsService.getTranslations().about.requiredName}}
        </div>
      </div>
    </div>
    <div class="me-subscribe-field" [@inputState]="inputState">
      <label for="lastname">{{_translationsService.getTranslations().about.lasname}}</label>
      <input type="text" formControlName="lastname" />
      <div
        *ngIf="subscriptionForm.controls['lastname'].invalid && (subscriptionForm.controls['lastname'].dirty || subscriptionForm.controls['lastname'].touched)"
        class="subscribe-validation">
        <div *ngIf="subscriptionForm.controls['lastname'].errors?.required">
          * {{_translationsService.getTranslations().about.requiredLastname}}
        </div>
      </div>
    </div>
    <div class="me-subscribe-field" [@inputState]="inputState">
      <label for="email">{{_translationsService.getTranslations().about.email}}</label>
      <input type="text" formControlName="email" />
      <div
        *ngIf="subscriptionForm.controls['email'].invalid && (subscriptionForm.controls['email'].dirty || subscriptionForm.controls['email'].touched)"
        class="subscribe-validation">
        <div *ngIf="subscriptionForm.controls['email'].errors?.required">
          * {{_translationsService.getTranslations().about.requiredEmail}}
        </div>
        <div *ngIf="subscriptionForm.controls['email'].errors?.email">
          * {{_translationsService.getTranslations().about.incorrectEmail}}
        </div>
      </div>
    </div>

    <button id="subscribeButton" [@buttonState]="buttonState" type="submit" (click)="subscribe()"
      [disabled]="subscriptionForm.controls['email'].errors?.email || subscriptionForm.controls['email'].errors?.required || subscriptionForm.controls['name'].errors?.required || subscriptionForm.controls['lastname'].errors?.required || isSubscribed"
      [ngClass]="{'subscribe-disabled': subscriptionForm.controls['email'].errors?.email || subscriptionForm.controls['email'].errors?.required || subscriptionForm.controls['name'].errors?.required || subscriptionForm.controls['lastname'].errors?.required}">
      {{ buttonState === 'default' ? _translationsService.getTranslations().about.subscribe :
      _translationsService.getTranslations().about.thanks }}
    </button>
  </form>
</main>

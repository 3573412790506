import { NgModule } from '@angular/core';
import { RouterModule } from '@angular/router';
import { CommonModule } from '@angular/common';
import { IllustrationsComponent } from './illustrations.component';
import { IllustrationCategoryComponent } from './illustration-category/illustration-category.component';

@NgModule({
    declarations: [
        IllustrationsComponent,
        IllustrationCategoryComponent
    ],
    imports: [
        CommonModule,
        RouterModule.forChild([
            { path: 'illustrations', component: IllustrationsComponent },
            { path: 'illustrations/:id', component: IllustrationCategoryComponent },
        ])
    ],
    providers: [],
    bootstrap: [
        IllustrationsComponent
    ],
    exports: [

    ]
})
export class IllustrationsModule {

}

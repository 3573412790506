import { trigger, state, style, transition, animate } from '@angular/animations';

export const inputAnimation = trigger('inputState', [
  state('default', style({
    opacity: '1',
  })),
  state('subscribed', style({
    opacity: '0',
  })),
  transition('default => subscribed', [
    animate('0.9s'),
  ]),
]);

import { Component, OnInit, Input } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { AngularFireAuth } from '@angular/fire/compat/auth';
import { CommentService, Comment } from '../comment.service';
import { TranslationsService } from '../translations/translations.service';
import { submitAnimation } from './animations';

@Component({
  selector: 'app-comment',
  templateUrl: './comment.component.html',
  styleUrls: ['./comment.component.scss'],
  animations: [submitAnimation]
})
export class CommentComponent implements OnInit {
  @Input() postId: string;
  @Input() postTitle: string;
  commentForm: FormGroup;
  comments: Comment[] = [];
  submitted: boolean = false;
  //editingCommentId: string | null = null;
  userId: string | null = null;

  submitState: string = 'default';

  constructor(
    private fb: FormBuilder,
    private _commentService: CommentService,
    private afAuth: AngularFireAuth,
    public _translationsService: TranslationsService
  ) {}

  ngOnInit(): void {
    this.commentForm = this.fb.group({
      name: ['', [Validators.required, Validators.pattern('^[a-zA-Z\u0400-\u04FF\\s]+$')]],
      email: ['', [Validators.required, Validators.email]],
      comment: ['', Validators.required],
      honeypot: ['']
    });

    // this.afAuth.user.subscribe(user => {
    //   if (user) {
    //     this.userId = user.uid;
    //   }
    // });

    this._commentService.getComments(this.postId).subscribe(comments => {
      this.comments = comments;
    });
  }

  onSubmit(): void {
    if (this.commentForm.get('honeypot').value) {return;}

    this.submitState = 'clicked';

    if (this.commentForm.valid) {
      const newComment: Comment = {
        ...this.commentForm.value,
        // userId: this.userId,
        moderated: false,
        timestamp: new Date().toISOString(),
        postTitle: this.postTitle
      };
      this._commentService.addComment(this.postId, newComment).then(() => {
        setTimeout(() => { this.submitState = 'default'; this.submitted = true;
          this.commentForm.reset(); }, 500);
      });
    }
  }

  // editComment(comment: Comment): void {
  //   this.editingCommentId = comment.id!;
  //   this.commentForm.patchValue({
  //     name: comment.name,
  //     email: comment.email,
  //     comment: comment.comment
  //   });
  // }

  // deleteComment(commentId: string): void {
  //   this._commentService.deleteComment(this.postId, commentId).then(() => {
  //     this.comments = this.comments.filter(comment => comment.id !== commentId);
  //   });
  // }

  // canEditOrDelete(comment: Comment): boolean {
  //   return comment.userId === this.userId && !comment.moderated;
  //   return false;
  // }
}

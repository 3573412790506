import { Injectable } from '@angular/core';
import { ContentfulService } from './blog/contentful.service';
import { Observable } from 'rxjs';

import {
  collection,
  CollectionReference,
  Firestore,
  query,
  collectionData,
} from '@angular/fire/firestore';
import { getDownloadURL, getStorage, ref } from '@angular/fire/storage';
import { LikeService } from './likes.service';

@Injectable({
  providedIn: 'root'
})
export class RemoteDataService {

  blogPosts = [];
  illustrations = {
    histories: [],
    emptiness: [],
    saveParents: []
  };
  illustrations_response: Observable<any>;


  constructor(private _service: ContentfulService, private _afs: Firestore, private _likesService: LikeService) { }

  loadPosts() {
    collectionData<any>(
      query<any, any>(
        collection(this._afs, `posts/`) as CollectionReference<any>
      ), { idField: 'id' }
    ).subscribe(snapshot => {
      snapshot.forEach(doc => {
        let post = { pictureURL: '', data: {}, bigId: doc.id, likes: 0 };

        this._likesService.getLikes(doc.id).subscribe(likes => {
          post.likes = likes;
        });
        post.data = doc;
        getDownloadURL(ref(getStorage(), 'images/' + doc.postId)).then(
          url => {
            post.pictureURL = url;
            this.blogPosts.push(post);
            this.blogPosts = this.blogPosts.sort((a, b) => (new Date(a.data.dateSystem) < new Date(b.data.dateSystem)) ? 1 : ((new Date(b.data.dateSystem) < new Date(a.data.dateSystem)) ? -1 : 0));
          }
        ).catch(err => {});
      });
    });
  }

  loadIllustrations() {
    this.illustrations_response = this._service.getAllIllustrations();
    this.illustrations_response.subscribe(() => { });
    this.illustrations_response.forEach(item => {
      item.items = item.items.filter(it => {
        if (it.fields.categoryId === 1) {
          this.illustrations.emptiness.push(
            it.fields
          );
        }
        if (it.fields.categoryId === 2) {
          this.illustrations.saveParents.push(
            it.fields
          );
        }
        if (it.fields.categoryId === 3) {
          this.illustrations.histories.push(
            it.fields
          );
        }
      });
    });
  }

  getIllustrations() {
    return this.illustrations;
  }

  getPosts() {
    return this.blogPosts;
  }
}

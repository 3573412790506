export const en = {
  menu: {
    about: 'About me',
    book: 'Books',
    illustrations: 'Illustrations',
    blog: 'Blog'
  },
  buttons: {
    back: 'Back',
    book: 'Book'
  },
  footer: {
    copyright: '© 2020 Copyright'
  },
  about: {
    irina: 'Irina Shilova',
    textHello: 'Hello!',
    textName: 'My name is Ira. I write books for children and teenagers in Russian, draw book illustrations, and write stories for adults.',
    textTravel: 'I was born in Veliky Novgorod and spent my childhood in the Vladimir region. As a child, I loved watching cartoons, drawing animals, writing stories with a ballpoint pen on a large sheet of paper, and walking in the woods. Almost nothing has changed in me since then. Except for a love of long adventures, I have been living in one country or another for many years. My husband and I now live in France, and before that, we explored the Czech Republic, Estonia, Norway, and Mexico. A life full of changes is great for highlighting what kind of person you are and where you need to grow. Travelling teaches me that every day you need to become stronger, kinder, and more mindful. No wonder the idea of self-development comes up so often in my stories.',
    textEducation: 'By the way, I started writing seriously and reverently after I helped a lot of other people\'s journeys come true. The fact is that I graduated from the Russian International Academy of Tourism and worked in the travel industry for several years, organising true adventures. And one day I wanted to have an adventure for myself—to fulfil my childhood dream and become a good writer and a skilled illustrator.',
    textFirstBook: 'My first book was "How to Save Parents,"  an illustrated story for kids who just started school. I love writing children\'s and teen literature because I believe a good book read at a young age prepares you for a happy, vibrant adult life. A good picture book prepares you even better.',
    textFirstBook2: 'But sometimes I think that there are a lot of people in the world who didn\'t have good books as children, and then I write stories for adults. To encourage and cheer up, or to scare and make you think.',
    textEnding: "Today I'm published by the Russian publishing platform Litres and by Russian indie publishers. You can also find me on Amazon. On this site, I collect all my stories, drawings, and notes because I want to show that a writer's journey is not an easy and long one. And if you're interested in watching my writing evolve, sign up for updates. I'll enjoy travelling with the company.",
    wantSubscribe: 'Subscribe',
    name: 'First name',
    lasname: 'Last name',
    email: 'Email',
    requiredName: 'First name is required',
    requiredLastname: 'Last name is required',
    requiredEmail: 'Email is required',
    wrongName: 'Name can consists from letters and spaces only',
    commentSubmitted: 'your comment awaits moderation',
    incorrectEmail: 'Check email address',
    subscribe: 'Subscribe!',
    placeholderName: 'Your name',
    placeholderLastname: 'Your last name',
    placeholderEmail: 'your@email.com',
    thanks: 'Thank you!',
    requiredComment: 'Where is you comment? :)',
    comment: 'Comment',
    submit: 'Submit',
    commentWriting: 'wrote:'
  },
  blogPage: {
    thanks: 'Thank you for reading'
  },
  imgCategories: {
    lostTomorrow: '',
    howToSaveParents: '',
    histories: ''
  },
  imgModal: {
    description: 'Description: ',
    tool: 'Tools:',
    originalSize: 'Original Size: '
  },
  signature: 'Irina Shilova',
  books: {
    buttons: {
      read: 'Read',
      order: 'Order',
      buy: 'Buy',
      play: 'Play',
      workInProgress: 'Currently I am working on this book.'
    },
    titles: {
      stories: {
        viktor: 'Victor\'s stories',
        janMark: 'Brothers Gorechavka stories'
      },
      trailers: {
        howToSaveParents: 'Booktrailer of a book "How to save parents"'
      },
      howToSaveParents: 'How to Save Parents',
      howToFixEmptiness: 'How to Fix Emptiness',
      fox: 'The Fox Skin',
      kamilla: 'Furious Fire',
      ultramarin: 'Ultramarine',
      tryapka: 'The Soft Rag',
      neesh: 'Do Not Eat Me',
      eternity: 'Bypassing The Divide Head For Eternity',
      end: 'The End',
      book: 'book',
      tale: 'Tale'
    },
    shortDescriptions: {
      viktorStories: 'Here you can look at the main character of the book - Victor, before he went on an adventure to save his parents',
      janMarkStories: 'Russian edition only yet :(',
      trailers: {
        howToSaveParents: 'Let\'s get acquainted with the book\'s characters and find out what adventures are waiting them.\n' +
          '\n' +
          '(Russian edition only. If you would like to see a English version, please, contact me.)'
      },
      howToSaveParents: 'An ordinary boy from a family who feels comfortable in their routine must save his parents from the revenge of a mysterious Stranger, but the child’s only skill is walking a dog.',
      howToFixEmptiness: 'Inseparable twin brothers set out to find the Firebird, a magical wish-fulfilling bird, but in this adventure they can only count on the help of bizarre talking animals who ask the guys to unravel the mystery of the missing supplies.',
      fox: 'The girl Lelya, unlike her mother and siblings, discovers a dark family secret, because of which she begins a journey away from home and from herself.',
      kamilla: 'Camilla has two features that define her life: the first is her temperament, which is difficult not to notice; the second is a story from the past that needs to be corrected for the sake of reconciliation with herself and her loved ones.',
      ultramarin: 'This short story, included in the print sale, is the confession of a man in love who, year after year, gets to know his wife better, but what is revealed to him causes desperate fear and disgust.',
      tryapka: 'A fearful, lonely woman, pursued by a stranger, carefully examines her memories to find in them a clue on how to get rid of the annoying shadow and how to worthily protect the meaning of her life.',
      neesh: 'A fairy tale about Little Red Riding Hood, who is hunted by the terrible Wolf. However, the Wolf does not live in the forest, but right in Little Red Riding Hood’s head.',
      eternity: 'One day, a human boy became friends with a troll fisherman, and together they learned to understand the river, the world, and life itself. But with the same power with which friendship revealed new knowledge to the boy and the troll, old habits and dissimilar tempers cruelly tested the friends.',
    }
  },
  illustrations: {
    categories: {
      howToSaveParents: 'How To Save Parents',
      howToFixEmptiness: 'How To Fix Emptiness',
      pictures: 'Pictures'
    },
    tooltips: {
      openOrigin: 'Open Original'
    }
  }
};

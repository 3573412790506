import {Component, OnInit} from '@angular/core';
import {TranslationsService} from '../translations/translations.service';
import { MetaTitleService } from 'src/app/meta-title.service';

@Component({
  selector: 'app-illustrations',
  templateUrl: './illustrations.component.html',
  styleUrls: ['./illustrations.component.scss']
})
export class IllustrationsComponent implements OnInit {

  constructor(public _translationsService: TranslationsService, private metaTitleService: MetaTitleService) {
  }

  ngOnInit() {
    this.metaTitleService.updateTitle('Иллюстрации');
    this.metaTitleService.updateTag('description', 'Иллюстрации Иры Шиловой, автора детских книг Как спасти родителей и Как исправить пустоту.');
  }
}

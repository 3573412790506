import { NgModule } from '@angular/core';
import { RouterModule } from '@angular/router';
import { CommonModule } from '@angular/common';
import { BookComponent } from './book.component';
import { StoryJanMarkComponent } from './histories/janMark/stories/jan-mark-story.component';
import { BookHowToFixEmptinessComponent } from './HowToFixEmptiness/book.howToFixEmptiness.component';
import { BookHowToSaveParentsComponent } from './HowToSaveParents/book.howToSaveParents.component';
import { HowToSaveParentsReadComponent } from './HowToSaveParents/read/how-to-save-parents-read.component'
import { BookFoxComponent } from './fox/book.fox.component';
import { BookKamillaComponent } from './kamilla/book.kamilla.component';
import { BookUltramarinComponent } from './ultramarin/book.ultramarin.component';
import { BookTryapkaComponent } from './tryapka/book.tryapka.component';
import { BookNeeshComponent } from './ne-esh/book.neesh.component';
import { BookEternityComponent } from './eternity/book.eternity.component';

@NgModule({
    declarations: [
        BookComponent,
        StoryJanMarkComponent,
        BookHowToFixEmptinessComponent,
        BookHowToSaveParentsComponent,
        HowToSaveParentsReadComponent,
        BookUltramarinComponent,
        BookTryapkaComponent,
        BookNeeshComponent,
        BookFoxComponent,
        BookKamillaComponent,
        BookEternityComponent
    ],
    imports: [
        CommonModule,
        RouterModule.forChild([
            { path: 'book', component: BookComponent },
            { path: 'history/janMark/story/:id', component: StoryJanMarkComponent },
            { path: 'book/category/howToSaveParents', component: BookHowToSaveParentsComponent },
            { path: 'book/category/howToFixEmptiness', component: BookHowToFixEmptinessComponent },
            { path: 'book/read/how-to-save-parents', component: HowToSaveParentsReadComponent },
            { path: 'book/category/lisya-shkurka', component: BookFoxComponent },
            { path: 'book/category/neistovy-ogon', component: BookKamillaComponent },
            { path: 'book/category/ultramarin', component: BookUltramarinComponent },
            { path: 'book/category/tryapka', component: BookTryapkaComponent },
            { path: 'book/category/ne-esh-menya', component: BookNeeshComponent },
            { path: 'book/category/eternity', component: BookEternityComponent },

        ])
    ],
    providers: [],
    bootstrap: [
        BookComponent
    ],
    exports: [

    ]
})
export class BookModule {

}
